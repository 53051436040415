import React, { Component } from 'react';
import './style.scss';
import CircleRadioButton from './circleRadioButton';

class DBOSRadioButton extends Component {
    constructor() {
        super();
    }

    changeActiveRadioButton(index) {
        this.onSelectedValueChange(this.props.radioItemList[index].value);
    }

    onSelectedValueChange = (itemValue) => {
        this.props.onSelectedValueChange(itemValue);
    }

    render() {
        return (
            <div style={{ flexDirection: 'row' }} >
                {
                    (this.props.type == 'circle') ?
                        (
                            <div className="circleRadioButtonContainer">
                                {
                                    this.props.radioItemList.map((item, key) =>
                                        (
                                            <CircleRadioButton
                                                key={key}
                                                button={item}
                                                name={this.props.name}
                                                selectedItem={this.props.selectedValue}
                                                onClick={this.changeActiveRadioButton.bind(this, key)}
                                                disabled={this.props.disabled}
                                            />
                                        ))
                                }
                            </div>
                        ) : ('')
                }
            </div>
        );
    }
}

export default DBOSRadioButton;