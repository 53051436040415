import React,{ Component } from 'react';
import { Badge } from 'reactstrap';
import './style.scss';

class EDDBadge extends Component {

    render() {
        return (
            <Badge className='eddBadge-body' pill>
                <div className='eddBadge-container'>
                    <i className="fa fa-user-o fa-lg eddBadge-icon"/>
                    <span className='eddBadge-text'>
                        {this.props.eddCode}
                    </span>
                </div>
            </Badge>
        )
    }
}

export default EDDBadge;