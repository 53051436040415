import RSA from './rsa';

/** 
 * General Settings
*/
const host = "https://biz.allianceplugin.io";

const dummyHost = "https://run.mocky.io";

const protectedPrefix = "/protected";

const publicPrefix = "/public";

const version = "/v1/";

const clientIdSecret = "cG9ydGFsOmI4ODE5YjA1LTJhYmEtNGFjZi05ODhhLWRlN2QyYjUzMDk3NQ==";

const modulus = 'a4d404bd262ac8f5a3c55eeef69914df3c97b44f57e54a38a70aad05c4351a98c52a87b135ceae75df5de25d88fcc4acfb8f79dd426ea674196cb1fd611a5c59180b2e743b6b9eac8297cab180ca0c5e8f83816dc0e0e0b8b8e1b7835c867e9f3b778e034a67a6b4b00be1090680154402c7ed1c154ddc0564af37f8169ac66ca4033ee6f59dfafc5cfc3de16d9bb4263b706a7bcefb352e14e473993ca94ed52d74c38bcd29342ae3eef33517d555788afff9b044e651f70949b557c77bac81f7ce434b38713d2bce483d62be44a9a9886007cac536ec4b26b31e5c04ae9be281d7fa7ef93cfde2488bf41c22802ddc0d63c079e1b2e3ad5ef1876aaf334e71';

const rsaKey = RSA.uatKey;

const appVersion = '1.0.0';
/**
 * Prefixes
 */
const eformPrefix = "dbob/eform";

const efacePrefix = "dbob/eface";

const productPrefix = "dbob/product";

const soapPrefix = "dbob/soap";

const authPrefix = "dbob/auth";

const eddPrefix = "dbob/edd";

const activationPrefix = "dbob/activation";

const scenterPrefix = 'dbob/scenter';

const auditPrefix = 'dbob/audit';

const remoteWealthEformPrefix = "wealth/eform";

const pportalPrefix = "/vcc/pportal";

/**
 * Endpoints Url
 */
const formUrl = eformPrefix + protectedPrefix + version + "forms";

const applicationUrl = formUrl + "/applications";

const faceUrl = efacePrefix + protectedPrefix + version + "face_verification";

const segmentsUrl = productPrefix + protectedPrefix + version + "segments";

const productUrl = productPrefix + protectedPrefix + version + "product_offerings";

const loginUrl = pportalPrefix + publicPrefix + version + "token";

const validateToken2fa = pportalPrefix + publicPrefix + version + "token/otp/validate";

const refreshSessionUrl = pportalPrefix + publicPrefix + version + "token";

const logoutUrl = pportalPrefix + protectedPrefix + version + "logout";

const soapUrl = soapPrefix + protectedPrefix + version;

const cifInquiryUrl = soapPrefix + protectedPrefix + version + 'cif/inquiry/';

const eddUrl = eddPrefix + protectedPrefix + version;

const activationUrl = activationPrefix + protectedPrefix + version;

const dashboardUrl = eformPrefix + protectedPrefix + version + 'dashboard/applications';

const scenterUrl = scenterPrefix + protectedPrefix + version;

const auditLogUrl = auditPrefix + protectedPrefix + version + 'audit';

const remoteWealthEformUrl = remoteWealthEformPrefix + protectedPrefix + version;

const getAllBranch = productPrefix + protectedPrefix + version + 'branches';

export default { host, dummyHost, eformPrefix, version, formUrl, applicationUrl, faceUrl, segmentsUrl, 
    productUrl, loginUrl, validateToken2fa, refreshSessionUrl, clientIdSecret, cifInquiryUrl, eddUrl, activationUrl, 
    dashboardUrl, logoutUrl, scenterUrl, auditLogUrl, modulus, soapUrl, remoteWealthEformUrl, rsaKey, appVersion, getAllBranch }
