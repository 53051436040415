import { withCustomAlert, withLoading, withSidebarRefreshHandler } from "hoc";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  FormGroup
} from "reactstrap";
import { FilterDateRange } from "../../../../components";
import { Webservice } from "../../../../services";
import customerListStyles from "../styles.module.scss";

const InAppPurchaseHistoryList = props => {

  const filterStatusOptions = [
    { value: "All", label: "All" },
    { value: "S", label: "Successful" },
    { value: "F", label: "Failed" },
  ];

  const getInitialFromDate = () => {
    return sessionStorage.getItem("fromDate")
      ? moment(sessionStorage.getItem("fromDate"))
      : moment();
  };

  const getInitialToDate = () => {
    return sessionStorage.getItem("toDate")
      ? moment(sessionStorage.getItem("toDate"))
      : moment();
  };

  const [fromDate, setFromDate] = useState(getInitialFromDate());
  const [toDate, setToDate] = useState(getInitialToDate());
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(0);
  const [size] = useState(100);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [transactionList, setTransactionList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const [csvLink, setCsvLink] = useState(null);

  const getDashboardList = isDownloadCsv => {
    if (fromDate && toDate) {
      props.showLoading();

      let request = {
        from_date: fromDate,
        to_date: toDate,
        page: page,
        size: size
      };
      if (selectedStatus && selectedStatus.value !== "All") {
        request = { ...request, payment_status: selectedStatus.value };
      }

      if (!isDownloadCsv) {
        props.hideLoading();
        Webservice.applications.getInAppPurchaseHistory({
          ...request
        })
          .then(response => {
            setTotalPage(
              Math.ceil(response.headers.get("X-Total-Count") / size)
            );

            response.json().then(r => {
              setTransactionList(r);
              props.hideLoading();
            });
          })
          .catch(error => {
            console.log("application list error: ", error);
            if (error.response && error.response.error) {
              props.alert(error.response.error);
              props.hideLoading();
            }
          });
      } else {
        Webservice.applications.getInAppPurchaseHistoryCsv({ ...request })
          .then(response => {
            response.text().then(r => {
              setDownloadData(r);
              csvLink.link.click();
              props.hideLoading();
            });
          })
          .catch(error => {
            console.log("application list csv error: ", error);
            if (error.response && error.response.error) {
              props.alert(error.response.error);
              props.hideLoading();
            }
          });
      }
    }
  };

  useEffect(() => {
    getDashboardList();
  }, [fromDate, toDate, page, selectedStatus]);

  const onSelectedDateRangeChange = data => {
    setSelectedDateRange(data);

    if (data) {
      const today = moment();
      const minusSevenDays = moment().subtract(6, "days");
      setToDate(today);

      if (data === "today") {
        setFromDate(today);
      } else if (data === "thisWeek") {
        setFromDate(minusSevenDays);
      }
    }
  };

  useEffect(() => {
    const today = moment().dayOfYear();
    const minusSevenDays = moment()
      .subtract(6, "days")
      .dayOfYear();

    if (fromDate && toDate) {
      if (fromDate.dayOfYear() === today && toDate.dayOfYear() === today) {
        setSelectedDateRange("today");
      } else if (
        fromDate.dayOfYear() === minusSevenDays &&
        toDate.dayOfYear() === today
      ) {
        setSelectedDateRange("thisWeek");
      } else {
        setSelectedDateRange(null);
      }

      sessionStorage.setItem("fromDate", fromDate);
      sessionStorage.setItem("toDate", toDate);
    }
  }, [fromDate, toDate]);

  const onFromDate = fromDate => {
    setFromDate(fromDate);
  };

  const onToDate = toDate => {
    setToDate(toDate);
  };

  const toggleFilterByDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onFilterStatusChange = event => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    const selectedOption = {
      value: value,
      label: name
    };

    setSelectedStatus(selectedOption);
  };

  const getStatusPillClass = status => {
    let cls = customerListStyles.statusPill;
    switch (status) {
      case "S":
        cls = cls + " " + customerListStyles.approved;
        return cls;

      case "F":
        cls = cls + " " + customerListStyles.rejected;
        return cls;
      
      default:
        return cls;
    }
  };

  const getStatusPillLabel = status => {
    switch (status) {
      case "S":
        return "Successful";

      case "F":
        return "Failed";
      
      default:
        return "-";
    }
  };

  return (
    <div className={customerListStyles.section}>
      <div className={customerListStyles.filterBar}>
        <h1>In-App Purchases Transaction History</h1>

        <div className={customerListStyles.filters}>
          <div className={customerListStyles.filtersLeft}>
            <FormGroup>
              <Label className="font">FILTER</Label>
              <Dropdown isOpen={isOpen} toggle={toggleFilterByDropdown} className={customerListStyles.dropdown}>
                <DropdownToggle caret className={customerListStyles.dropdownToggle}>
                  {selectedStatus ? selectedStatus.label : "Please select"}
                </DropdownToggle>

                <DropdownMenu>
                  {filterStatusOptions.map(option => {
                    return (
                      <DropdownItem
                        key={option.value}
                        onClick={onFilterStatusChange}
                        value={option.value}
                        name={option.label}
                      >
                        {option.label}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </div>

          <FilterDateRange
            startDate={fromDate}
            endDate={toDate}
            onFromDate={onFromDate}
            onToDate={onToDate}
            showContainer
            handleClickToday={data => onSelectedDateRangeChange(data)}
            handleClickThisWeek={data => onSelectedDateRangeChange(data)}
            handleClear={data => onSelectedDateRangeChange(data)}
            selectedOption={selectedDateRange}
          />
        </div>
      </div>

      <div className={customerListStyles.secondaryFilters}>
        <div className={customerListStyles.exportCsv}>
          <Button
            type="button"
            className={customerListStyles.primaryOutlined}
            onClick={() => getDashboardList(true)}
          >
            Export CSV
          </Button>
        </div>
        <div>
          <CSVLink
            data={downloadData}
            filename="vcc_in_app_payment_history.csv"
            className="hidden"
            ref={r => setCsvLink(r)}
            target="_blank"
          />
        </div>
      </div>

      <div className={customerListStyles.customerList}>
        <div className={customerListStyles.tableContainer}>
          <table className={customerListStyles.table}>
            <thead>
              <tr>
                <th>Transaction Date</th>
                <th>Time</th>
                <th>Transaction Amount (RM)</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {transactionList.map((item,i) => {
                return (
                  <tr key={"vcc-app-"+i}>
                    <td>{!!item.transactionDateTime ? moment(item.transactionDateTime).format("DD/MM/YYYY") : "-"}</td>
                    <td>{!!item.transactionDateTime ? moment(item.transactionDateTime).format("hh:mm A") : "-"}</td>
                    <td>{parseFloat(item.totalAmount).toFixed(2) || "-"}</td>
                    <td>
                      <span
                        className={getStatusPillClass(item.paymentStatus)}
                      >
                        {getStatusPillLabel(item.paymentStatus)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {totalPage > 0 && (
          <nav className={customerListStyles.nav}>
            <ul className={'pagination'}>
              <li className={`page-item${page === 0 ? " disabled" : ""}`}>
                <a className="page-link" onClick={() => setPage(0)}>
                  First
                </a>
              </li>

              <li className={`page-item${page === 0 ? " disabled" : ""}`}>
                <a className="page-link" onClick={() => setPage(page - 1)}>
                  Previous
                </a>
              </li>

              {[...Array(totalPage).keys()].map(a => (
                <li
                  key={a}
                  className={`page-item${page === a ? " active" : ""}`}
                >
                  <a className="page-link" onClick={() => setPage(a)}>
                    {a + 1}
                  </a>
                </li>
              ))}

              <li
                className={`page-item${
                  page === totalPage - 1 ? " disabled" : ""
                }`}
              >
                <a className="page-link" onClick={() => setPage(page + 1)}>
                  Next
                </a>
              </li>

              <li
                className={`page-item${
                  page === totalPage - 1 ? " disabled" : ""
                }`}
              >
                <a className="page-link" onClick={() => setPage(totalPage - 1)}>
                  Last
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default withSidebarRefreshHandler(
  withLoading(withCustomAlert(InAppPurchaseHistoryList))
);
