import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
import './ContactSupportModal.scss';

class ContactSupportModal extends Component {

    render() {
        return (
            <Modal isOpen={this.props.toggleModal}>
                <ModalHeader toggle={this.props.onToggle}>Contact Support</ModalHeader>
                <ModalBody>
                    <div>
                        <h4 className="contactSupportTitle">1. How do i login?</h4>
                        <ul>
                            <li>Only authorised staff of Alliance Bank is allowed to login to DBOS.</li>
                            <li>To login, please enter your staff LAN ID and password.</li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="contactSupportTitle">2.  How do i unlock ID when ID is locked?</h4>
                        <ul>
                            <li>To unlock your ID, please contact System Access Control helpline: 03-2604 2756</li>
                            <li>Once ID is unlock, you have to login using your existing staff LAN ID and Password</li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="contactSupportTitle">3.  How do i change my password?</h4>
                        <ul>
                            <li>Go to inAlliance Portal, and select the quick link to IS Self-Service Portal.</li>
                            <li>Click on the "Change Password" tab.</li>
                            <li>Key in your User Name, Current Password, New Password, and submit.</li>
                            <li>If further assistance is required, please contact System Access Control helpline: 03-2604 2756</li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ContactSupportModal;



