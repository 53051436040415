import { Session } from 'core';
import { CustomError } from 'core';
import { SessionService } from 'services';
import { HTTP } from 'core';
import Environment from 'environment';

let tokenTimer = null;
const timeMarginInSecond = 120;
let isPauseRefreshSession = false

export const login = (username, password) => {

    return Session.login(username, password).then((response) => {

        console.log("login response:", response);
        if (!!response.otpRequired){
            clearSession();
            return Promise.resolve(response);
        } else {
            if (!response.error) {
                clearSession();
                requestSuccess(response);
                storeToken(response);
                return Promise.resolve(response);
            } else {
                clearSession();
                const loginError = new CustomError().loginError(response.error_description);
                return Promise.reject(loginError);
            }
        }

    }).catch((error) => {
        clearSession();
        return Promise.reject(error);
    })
}

export const validateToken2fa = (username, password, otpId, tac) => {
    return Session.validateToken2fa(username, password, otpId, tac)
        .then((response) => {
            if (!response.error) {
                clearSession();
                requestSuccess(response);
                storeToken(response);
                return Promise.resolve(response);
            } else {
                clearSession();
                const loginError = new CustomError().loginError(response.error_description);
                return Promise.reject(loginError);
            }
        })
        .catch((error) => {
            clearSession();
            return Promise.reject(error);
        })
}

export const refreshSession = () => {

    const refreshToken = sessionStorage.getItem('pportal-web:refreshToken')

    if (refreshToken) {
        Session.refreshSession(refreshToken).then(response => {

            console.log("refreshSession response:", response);

            if (!response.error) {
                clearSession();
                requestSuccess(response);
                storeToken(response);
            } else {
                alert(response.error + ":" + response.error_description);
                clearSession();
            }
        }).catch((error) => {
            console.log("Unable to refresh token:reason:", error.response.error_description);
            alert("Your session has timed-out. Please sign-in again.");
            clearSession();
        })
    } else {
        clearSession();
        console.log("Invalid token,unable to refresh session.Please login again.")
        alert("Your session has timed-out. Please sign-in again.");
    }

}

export const logout = (isProtectedWebService = true) => {
    console.log('logout')

    if (isProtectedWebService && !SessionService.isValidSession()) {
        const error = new CustomError().refreshTokenExpired();
        return Promise.reject(error);
    }
    let params = undefined;
    return HTTP.postData(Environment.logoutUrl, params, isProtectedWebService);
}

export const clearSession = () => {
    clearTimeout(tokenTimer);
    sessionStorage.removeItem('pportal-web:accessToken');
    sessionStorage.removeItem('pportal-web:refreshToken');
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
}

const storeToken = (response) => {
    sessionStorage.setItem('pportal-web:accessToken', response["access_token"]);
    sessionStorage.setItem('pportal-web:refreshToken', response["refresh_token"]);
}

window.onfocus = () => {
    isPauseRefreshSession = false
}

window.onblur = () => {
    isPauseRefreshSession = true
}

const requestSuccess = (response) => {
    tokenTimer = setTimeout(() => { 
        if (!isPauseRefreshSession) {
            refreshSession()
        }
    }, ((response.expires_in - timeMarginInSecond) * 1000));
}

const getRoles = (accessToken) => {

    const jwt = accessToken.split('.');

    const payload = JSON.parse(atob(jwt[1]));

    return payload.realm_access.roles;

}

export const isOfficer = (accessToken) => {

    const roles = getRoles(accessToken);

    return (roles.indexOf('branch_officer') >= 0);
}

export const isSupervisor = (accessToken) => {

    const roles = getRoles(accessToken);

    return (roles.indexOf('branch_manager') >= 0);
}

export const getLoginUsername = () => {
    const accessToken = sessionStorage.getItem('pportal-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        const username = payload.username;
        return username;
    }
    return;
}

export const getHeaderUsername = () => {
    const accessToken = sessionStorage.getItem('pportal-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        const name = payload.name;
        return name;
    }
    return;
}

export const getSupervisorPayload = () => {
    const accessToken = sessionStorage.getItem('pportal-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        if (isSupervisor(accessToken)) {
            const jwt = accessToken.split('.');
            const payload = JSON.parse(atob(jwt[1]));
            return payload;
        }
    }
    return;
}

export const getUserPayload = () => {
    const accessToken = sessionStorage.getItem('pportal-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
            const jwt = accessToken.split('.');
            const payload = JSON.parse(atob(jwt[1]));
            return payload;
    }
    return;
}

export const isValidSession = () => {
    let accessToken = sessionStorage.getItem('pportal-web:accessToken');
    let refreshToken = sessionStorage.getItem('pportal-web:refreshToken');

    if (!!accessToken && !!refreshToken)
        return true;

    return false;
}

export const isMaker = () => {
    let userAccess = getUserPayload().user_access;
    let isHavingCanDoAccActivation  = (userAccess.canDoAccActivation !== undefined && userAccess.canDoAccActivation !== null);

    if(isHavingCanDoAccActivation)
    return userAccess.canDoAccActivation;

    return userAccess.canDoAccOpen;
}

export const isOffsiteMaker = (accessToken) => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canDoOffsiteAccOpen;
}

export const isChecker = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canAuthorize;
}

export const canViewAllBranchDebitCardReport = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canViewPrintAllBranchDebitCardReport;
}

export const canViewAllBranchAccountCreationReport = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canViewPrintAllBranchDebitCardReport;
}

export const canViewOwnBranchDebitCardReportOnly = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canViewPrintOwnBranchDebitCardReport;
}

export const canViewOwnBranchAccountCreationReportOnly = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canViewPrintOwnBranchAccCreationReport;
}

export const canDoOffsiteActivation = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canDoOffsiteActivation;
}

export const canUpdateSendISA = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canUpdateSendISA;
}

export const canSendSubscriptionForm = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canSendSubscription;
}

export const canUploadProductMaintenanceFile = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canUploadProductMaintenanceFile;
}

export const canSearchCustomer = () => {
    let userAccess = getUserPayload().user_access;

    return userAccess.canSearchCustomer;
}


