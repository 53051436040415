const featureUpdatedList =

    [{
        label: "newFeatures",
        value: ["1. Purging data in DBOS system after x number of days based on application status."]
    },

    {
        label: "notification",
        value: ["-"]
    },

    {
        label: "newBugFix",
        value: ["1. Contact address postcode & city fields displayed in DBOS Support Center should correspond to what was entered on the tablet."]
    }]

export default featureUpdatedList