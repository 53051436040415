import React, { Component } from 'react';
import { Label, FormGroup } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import moment from "moment";

class Calendar extends Component {

  onChange=(selectedDate)=>{
    
    if(selectedDate===null)
    return;

    this.props.onChange(selectedDate);
  }

  
  render() {
    return (
      <FormGroup>
        <Label htmlFor={this.props.dateId}>{this.props.label}</Label>
        <div className={"controls flex-container" + (this.props.isFastCash ? "-fastCash": "")}>
          <div className="icon-container">
            <i className="fa fa-calendar calender-icon" aria-hidden="true"></i>
          </div>
          <div className="date-picker-container">
            <DatePicker
              id={this.props.dateId}
              className="form-control"
              selected={this.props.selectedValue}
              onChange={this.onChange}
              dateFormat="DD/MM/YYYY"
              placeholderText={this.props.placeHolder? this.props.placeHolder:"dd/mm/yyyy"}
              minDate={this.props.minDate? this.props.minDate:null}
              maxDate={this.props.maxDate? this.props.maxDate : moment()}
              calendarContainer={this.props.calendarContainer}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </FormGroup>
    );
  }
}

export default Calendar;