import React, { Component } from "react";
import styles from "./Input.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';

class InputWithValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      focused: false
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  }

  handleBlur = () => {
    this.setState({
      touched: true,
      focused: false
    });
  }

  handleChange = (e) => {
    const value = e.target.value;
    if (this.props.prefixLabel && this.props.suffixLabel){
      let removedValue = this.removePrefix(value);
      this.props.onChange(this.removeSuffix(removedValue));
    } else if (this.props.prefixLabel){
      this.props.onChange(this.removePrefix(value));
    } else if (this.props.suffixLabel) {
      this.props.onChange(this.removeSuffix(value));
    } else if (this.props.prependLabel) {
      this.props.onChange(this.appendPrependLabel(value));
    } else if (this.props.mask) {
      this.props.onChange(this.removeMask(value));
    } else {
      this.props.onChange(value);
    }
  }

  removePrefix = (val) => {
    const prefix = this.props.prefixLabel;
    const removedPrefixValue = val.replace(prefix, '');
    return removedPrefixValue;
  }

  removeSuffix = (val) => {
    const suffix = this.props.suffixLabel;
    const removedSuffixValue = val.replace(suffix, '');
    return removedSuffixValue;
  }

  removeMask = (val) => {
    const mask = this.props.mask;
    const removedMaskValue = val.replace(new RegExp(mask, 'g'), ' ');
    return removedMaskValue;
  }

  removePrepend = (val) => {
    const prepend = this.props.prependLabel;
    const removedPrependValue = val.replace(prepend, '');
    return removedPrependValue;
  }

  appendPrependLabel = (val) => {
    return this.props.prependLabel + val;
  }

  renderLabel = () => {
    if(!!this.props.label){
      return(
        <label htmlFor={this.props.name} className={ styles["show"] } >
        {this.props.label}
      </label>
      )
    }else{
    return ('');}
  }

  renderGreenTick = () => {
    return (
      <div className={!!this.props.label? (this.props.customStyle ? styles["short-form-tick"] : styles["form-tick"]) : (styles["nolabel-form-tick"])}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
    );
  };

  shouldRenderGreenTick = () =>{
    const { shouldShowLoadingIndicator, value, shouldShowGreenTick, isValidInput } = this.props;
    return value && isValidInput(value) && !shouldShowLoadingIndicator && shouldShowGreenTick;
  }

  shouldShowLabel = () =>{
    const { focused } = this.state;
    const { isValidInput, value } = this.props;
    return (focused || isValidInput(value)) && value !== '';
  }

  shouldShowError = () =>{
    const { touched } = this.state;
    const { optional, isValidInput, value } = this.props;
    return touched && (value ? !isValidInput(value) : true) && !optional;
  }

  shouldShouldNoteText = () =>{
    const { touched } = this.state;
    const { isValidInput, value } = this.props;
    return (!value && !touched) || (touched && isValidInput(value))
  }

  render() {
    const { prefixLabel, suffixLabel, format, thousandSeparator, mask, decimalSeparator, decimalScale, fixedDecimalScale,
      errText, noteText, value, width, name, placeholder, spellCheck, 
      autoComplete, disabled, id, maxLength, required, specialClass, type, prependLabel, isAllowed, allowNegative } = this.props;

    return (
      <div>
        <div
          className={`kyi-input-wrapper ${styles["kyi-input-wrapper"]}`}
          style={{
            position: "relative",
            width:
              width === "quarter"
                ? "25%"
                : width === "half"
                  ? "50%"
                  : width === "full"
                    ? "100%"
                    : "100%"
          }}
        >
          {this.renderLabel()}
          {
            format || prefixLabel || suffixLabel || thousandSeparator || decimalSeparator || mask || decimalScale ?
              (<NumberFormat
                name={name}
                className={`form-control input-field ${styles["kyi-input"]} ${this.shouldShowError() ? "is-invalid" : ""} ${specialClass}`}
                onChange={this.handleChange}
                value={value}
                placeholder={placeholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                format={format}
                mask={mask}
                spellCheck={spellCheck}
                autoComplete={autoComplete}
                disabled={disabled}
                thousandSeparator={thousandSeparator?thousandSeparator:','}
                prefix={prefixLabel}
                suffix={suffixLabel?suffixLabel:''}
                decimalScale={decimalScale?decimalScale:20}
                fixedDecimalScale={fixedDecimalScale}
                decimalSeparator={decimalSeparator?decimalSeparator:'.'}
                isAllowed={isAllowed}
                getInputRef = {refEl => (this.props.assignInputElement?this.props.assignInputElement(refEl):null)} 
                allowNegative={allowNegative}
              />) :
              (
                <div>
                  {prependLabel &&
                    (<div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">{prependLabel}</div>
                      </div>
                      <input
                        id={id}
                        name={name}
                        type={type}
                        className={`form-control input-field ${styles["kyi-input"]} ${this.shouldShowError() ? "is-invalid" : ""} ${specialClass}`}
                        required={required}
                        spellCheck={spellCheck}
                        autoComplete={autoComplete}
                        maxLength={maxLength}
                        onFocus={this.handleFocus}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={placeholder}
                        value={this.removePrepend(value)}
                        disabled={disabled}
                        ref={refEl => (this.props.assignInputElement?this.props.assignInputElement(refEl):null)}
                      />
                    </div>)
                  }
                  {!prependLabel &&
                    <input
                      id={id}
                      name={name}
                      type={type}
                      className={`form-control input-field ${styles["kyi-input"]} ${this.shouldShowError() ? "is-invalid" : ""} ${specialClass}`}
                      required={required}
                      spellCheck={spellCheck}
                      autoComplete={autoComplete}
                      maxLength={maxLength}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      placeholder={placeholder}
                      value={value}
                      disabled={disabled}
                      ref={refEl => (this.props.assignInputElement?this.props.assignInputElement(refEl):null)}
                    />
                  }
                </div>
              )
          }
          {this.shouldRenderGreenTick()&&this.renderGreenTick()}
        </div>
        {this.shouldShowError() ? <p className={styles["tips-msg"]}>{errText}</p> : ""}
        { this.shouldShouldNoteText() ? <p className={styles["note-msg"]}>{noteText}</p> : ""}
      </div>
    );
  }
}

InputWithValidation.defaultProps = {
  onChange: () =>{ },
  prefixLabel: null,
  suffixLabel: null,
  shouldShowGreenTick:true,
  isValidInput: ()=>true,
  value:'',
  optional:false,
  name:'input-1',
  label:'',
  prependLabel:'',
  shouldShowLoadingIndicator:false,
  format:null,
  thousandSeparator:false,
  decimalSeparator:false,
  mask:'',
  placeholder: '',
  spellCheck:'false',
  autoComplete:'false',
  disabled:false,
  id:'input-id-1',
  maxLength:99999,
  required:true,
  errText:'',
  noteText:'',
  specialClass:'',
  width:'full',
  type:'text',
  decimalScale: false,
  fixedDecimalScale: false
}

InputWithValidation.propTypes = {
  onChange: PropTypes.func,
  prefixLabel: PropTypes.string,
  suffixLabel: PropTypes.string,
  shouldShowGreenTick: PropTypes.bool,
  isValidInput: PropTypes.func,
  value: PropTypes.any,
  optional:PropTypes.bool,
  name:PropTypes.string,
  label:PropTypes.string,
  prependLabel:PropTypes.string,
  shouldShowLoadingIndicator:PropTypes.bool,
  format:PropTypes.any,
  thousandSeparator:PropTypes.bool,
  decimalSeparator:PropTypes.bool,
  mask:PropTypes.string,
  placeholder:PropTypes.string,
  spellCheck:PropTypes.string,
  autoComplete:PropTypes.string,
  disabled:PropTypes.bool,
  id:PropTypes.string,
  maxLength:PropTypes.number,
  required:PropTypes.bool,
  errText:PropTypes.string,
  noteText:PropTypes.string,
  specialClass:PropTypes.string,
  width:PropTypes.string,
  type:PropTypes.string,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool
}

export default InputWithValidation;
