import React, { Component } from "react";
import { Button } from "reactstrap";
import "./FilterRadio.scss";

class FilterRadio extends Component {
  render() {
    return (
      <div className="filterRadio">
        {this.props.list.map((item, key) => (
          <Button
            key={key}
            className={
              this.props.customStyle ? this.props.customStyle[key] : ""
            }
            onClick={() => this.props.onTapped(item)}
            active={
              this.props.selectedValue.value === item.value ? true : false
            }
          >
            {item.label}
            {item.amount ? <span>({item.amount})</span> : <span />}
          </Button>
        ))}
      </div>
    );
  }
}

export default FilterRadio;
