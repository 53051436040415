import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import './style.scss';

const withCustomAlert = (WrappedComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isOpen: false,
                text:null
            }
        }

        showAlert = (text) =>{
            this.setState({
                isOpen:true,
                text:text
            })
        }

        onDismiss = () =>{
            this.setState({
                isOpen:false,
                text:null
            })
        }

        isAlertOpen = () => {
            return this.state.isOpen;
        }

        render() {
            return (
                <React.Fragment>
                    <Alert className='custom-alert' isOpen={this.state.isOpen}>
                        {this.state.text}
                        <button type="button" className="close" aria-label="Close" onClick={this.onDismiss}>
                            <span style={{color:'white'}} aria-hidden="true">&times;</span>
                        </button>
                    </Alert>
                    <WrappedComponent alert={this.showAlert} onDismiss={this.onDismiss} isAlertOpen={this.isAlertOpen} {...this.props}/>
                </React.Fragment>
            )
        }
    }
}

export default withCustomAlert;