import { Calendar } from "components";
import React, { Component } from "react";
import { CalendarContainer } from "react-datepicker";
import { Button } from "reactstrap";
import "./FilterDateRange.scss";

class FilterDateRange extends Component {
  constructor(props) {
    super(props);
  }

  onClickToday() {
    const { handleClickToday } = this.props;
    handleClickToday("today");
  }

  onClickThisWeek() {
    const { handleClickThisWeek } = this.props;
    handleClickThisWeek("thisWeek");
  }

  onClickClear() {
    const { handleClear } = this.props;
    handleClear("today");
  }

  filterDateRangeContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div className="filter-date-range-container">
          <span>Show report for:</span>

          <div className="filter-dates">
            <Button
              onClick={() => this.onClickToday()}
              active={this.props.selectedOption === "today" ? true : false}
            >
              Today
            </Button>

            <Button
              onClick={() => this.onClickThisWeek()}
              active={this.props.selectedOption === "thisWeek" ? true : false}
            >
              This Week
            </Button>
          </div>
        </div>

        <div style={{ position: "relative" }}>{children}</div>

        <div className="clear-button">
          <Button value="thisWeek" onClick={() => this.onClickClear()}>
            Clear
          </Button>
        </div>
      </CalendarContainer>
    );
  };

  render() {
    return (
      <div className="filter-date-range">
        {this.props.isShowLeftLabel && (
          <div className="filter-date-label">Date Range</div>
        )}

        <div className="filter-date-cal from">
          <Calendar
            dateId={"date-from"}
            label={"From"}
            selectedValue={this.props.startDate}
            onChange={this.props.onFromDate}
            minDate={this.props.minimum}
            calendarContainer={
              this.props.showContainer ? this.filterDateRangeContainer : null
            }
          />
        </div>

        <div className="filter-date-cal to">
          <Calendar
            dateId={"date-to"}
            label={"To"}
            selectedValue={this.props.endDate}
            onChange={this.props.onToDate}
            minDate={this.props.minimum}
            calendarContainer={
              this.props.showContainer ? this.filterDateRangeContainer : null
            }
          />
        </div>
      </div>
    );
  }
}

FilterDateRange.defaultProps = {
  isShowLeftLabel: true
};

export default FilterDateRange;
