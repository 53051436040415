import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import './style.scss';

export default class ImageModal extends Component {

    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered={true} size="lg">
                <ModalBody>
                    <div className='custom-modal-container'>
                        <div className='custom-modal-header-container'>
                            <span className='custom-header-title'>Image</span>
                            <Button onClick={this.onDismiss} className='modal-close-button'>
                                <i className="fa fa-times-circle-o fa-lg modal-close-icon" />
                            </Button>
                        </div>
                        {this.renderFullImageView()}
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    renderFullImageView = () => {
        const { base64Image, label } = this.props.previewImage;
        return (
            <div className='modal-image-container'>
                <img src={'data:image/png;base64,' + base64Image} className='full-image-dimension' alt="" />
                <div className='modal-label-container'>
                    <p className='modal-label'> {label} </p>
                </div>
            </div>
        )
    }

    onPreviewClosed = () => {
        this.props.dismissPreview();
    }

    onLabelChange = (base64Image, label, id) => {
        this.props.onLabelChange(base64Image, label, id)
    }

    onSavePress = (id, label) => {
        this.props.onSavePress(id, label);
        this.props.dismissPreview();
    }

    onDismiss = () => {
        this.props.dismissPreview();
    }
}
