const uatKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkjlcCMmFXxlYScJyyoGB
                X4KCkXplVg/nrv7wmfi6eC/3T+4r03Y0nAzoFgb4LzO7Poyxou4KLuDjlWLa65Rt
                HqrPVBtxjKWc+tAOasGQ8QJTWitVQCERZHmD+4R5DeJ7xUDS9CsXUyhFfSRKjKzI
                0ZJhdgHBn5qEMHFtc/HQzN3jRvup1YS4EICUAl/cCQ2WTzHoR0w4K5T0Y9p9WTO3
                P5hPHcSWK7VahW25M2iCMx/pSxjnvNUvxWFmsEUB1HfJ/jbCB8RxN1nlnza4Yfn5
                gu5iUcRxQ1XoRBap0RIblRSYBK+d/lf5Nk6EsN9+cVfNgISjNO4g42f6p8MLWXJy
                ewIDAQAB
                -----END PUBLIC KEY-----
                `;
const prodKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkjlcCMmFXxlYScJyyoGB
                X4KCkXplVg/nrv7wmfi6eC/3T+4r03Y0nAzoFgb4LzO7Poyxou4KLuDjlWLa65Rt
                HqrPVBtxjKWc+tAOasGQ8QJTWitVQCERZHmD+4R5DeJ7xUDS9CsXUyhFfSRKjKzI
                0ZJhdgHBn5qEMHFtc/HQzN3jRvup1YS4EICUAl/cCQ2WTzHoR0w4K5T0Y9p9WTO3
                P5hPHcSWK7VahW25M2iCMx/pSxjnvNUvxWFmsEUB1HfJ/jbCB8RxN1nlnza4Yfn5
                gu5iUcRxQ1XoRBap0RIblRSYBK+d/lf5Nk6EsN9+cVfNgISjNO4g42f6p8MLWXJy
                ewIDAQAB
                -----END PUBLIC KEY-----
                `;
                
export default {
    uatKey, prodKey
}