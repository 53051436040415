import React from 'react';

const withSidebarRefreshHandler = (WrappedComponent) => {
    return class extends React.Component{
        
        sidebarRoute = null;
        action = null;

        registerSidebarRefresher = (sidebarRoute, action) => {
            this.sidebarRoute = sidebarRoute;
            this.action = action;
            document.querySelectorAll(`a[href='#${sidebarRoute}']`)[0].addEventListener('click', action)
        }

        componentWillUnmount(){
            if(this.sidebarRoute && this.action){
                document.querySelectorAll(`a[href='#${this.sidebarRoute}']`)[0].removeEventListener('click', this.action)
            }
        }

        render(){
            return <WrappedComponent {...this.props} registerSidebarRefresher={this.registerSidebarRefresher}/>
        }
    }
}

export default withSidebarRefreshHandler;