import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  InputGroup,
  Row,
  Nav
} from "reactstrap";
import { SessionService } from "services";
import { withCustomAlert } from "hoc";
import { withLoading } from "hoc";
import loginStyles from "./login.module.scss";
import headerStyle from "../../../containers/DefaultLayout/header.module.scss";
import { AppHeader } from "@coreui/react";
import ContactSupportModal from "./ContactSupportModal";
import { Footer, SCToast } from "components";
import Environment from "../../../environment";
import FeatureUpdatedModal from "./FeatureUpdatedModal";
import HelpGuide from "../../../containers/DefaultLayout/HelpGuide";
import { Webservice } from "../../../services";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loginAs: "",
      otpId: "",
      otp: "",
      otpMobileNo: "",
      otpResendLock: true,
      formView: "role",
      isError: false,
      showHelpGuide: false,
      forgotEmail: "",
      forgotEmailSent: false
    };
  }

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.error === "InvalidSession"
    ) {
      this.props.alert("Invalid Session, Please login to continue");
    }
    console.log(this.props.location);
    if(
      this.props.location.state &&
      !!this.props.location.state.otpId 
    ) {
      let locState = this.props.location.state;
      this.setState({
        username: locState.username,
        password: locState.password,
        otpId: locState.otpId,
        otpMobileNo: locState.mobileNo,
        formView: "otp"
      })
    }
  }

  inputOnchange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  isDisabled = (): Boolean => {
    const { loginAs, toggleLoginForm, username, password, otp, forgotEmail } = this.state;
    if (this.state.formView === "role" && !!!this.state.loginAs) {
      return true;
    }

    if (this.state.formView === "login") {
      return !!!username || !!!password;
    }

    if (this.state.formView === "otp") {
      return !!!otp;
    }

    if (this.state.formView === "forgotPw") {
      let emailReg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/g
      return !!!forgotEmail || !emailReg.test(forgotEmail);
    }

    return false;
  };

  onSubmit = event => {
    this.props.showLoading();
    this.props.onDismiss();
    switch (this.state.formView) {
      case "role": {
        if (this.state.loginAs === "dev") {
          window.location.href =
            // uat
            "https://uat.api.dbosuat.corp.alliancebg.com.my/login";
            // prod
            // "https://www.lifestylebanking.io/";
        } else {
          this.setState({
            formView: "login"
          });
          this.props.hideLoading();
        }
        break;
      }
      case "login": {
        this.loginActions();
        break;
      }
      case "otp": {
        this.otpActions();
        break;
      }
      case "forgotPw": {
        this.forgotPasswordActions();
        break;
      }
      default:
        break;
    }
    event.preventDefault();
  };

  loginActions = () => {
    this.props.showLoading();
    this.props.onDismiss();
    const { username, password } = this.state;
    sessionStorage.clear();
    SessionService.login(username, password)
      .then(resp => {
        this.props.hideLoading();
        console.log("login successfully");
        if (!!resp.otpRequired) {
          this.setState({
            formView: "otp",
            otpId: resp.otpId,
            otpMobileNo: resp.mobile,
            otpResendLock: true
          });
          setTimeout(()=>{
            this.setState({
              otpResendLock: false
            });
          },60000);
        } else {
          this.postLoginAction();
        }
      })
      .catch(error => {
        console.log("login:error:", error.response);
        if (
          !!error.response &&
          !!error.response.info &&
          !!error.response.info.code &&
          error.response.info.code === "PP0003"
        ) {
          this.props.navigation.pushTo("/changePassword", "", {
            username: username
          });
        } else if (
          !!error.response &&
          !!error.response.info &&
          !!error.response.info.message
        ){
          this.props.alert(error.response.info.message.replace(/\[(.*?)\]/g, ""));
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        } else {
          this.props.alert(error.message);
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        }

        this.props.hideLoading();
      });
  };

  postLoginAction = () => {
    if (
      this.props.location.state &&
      !!this.props.location.state.onReAuthenticatedUrl
    ) {
      let onReAuthenticatedUrl = this.props.location.state.onReAuthenticatedUrl;
      this.props.navigation.replace(onReAuthenticatedUrl);
    } else {
      this.props.navigation.replace("/home/");
    }
  };

  removeErrorWarning = () => {
    this.setState({
      isError: false
    });
  };

  otpActions = () => {
    const { username, password, otpId, otp } = this.state;
    SessionService.validateToken2fa(username, password, otpId, otp)
      .then(resp => {
        this.postLoginAction();
      })
      .catch(error => {
        console.log("login:error:", error.response);
        if (
          !!error.response &&
          !!error.response.info &&
          !!error.response.info.code &&
          error.response.info.code === "PP0003"
        ) {
          this.props.navigation.pushTo("/changePassword", "", {
            username: username
          });
        } else if (
          !!error.response &&
          !!error.response.info &&
          !!error.response.info.message
        ){
          this.props.alert(error.response.info.message.replace(/\[(.*?)\]/g, ""));
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        } else {
          this.props.alert(error.message);
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        }

        this.props.hideLoading();
      });
  };

  otpCancel = () => {
    this.setState({
      username: "",
      password: "",
      otpId: "",
      otp: "",
      otpMobileNo: "",
      formView: "login",
      isError: false
    });
  };

  showForgotPassword = () => {
    this.setState({
      username: "",
      password: "",
      otpId: "",
      otp: "",
      otpMobileNo: "",
      formView: "forgotPw"
    })
  } 

  hideForgotPassword = () => {
    this.setState({
      formView: "login"
    })
  } 

  forgotPasswordActions = () => {
    const { forgotEmail } = this.state;
    Webservice.applications.forgotPassword(forgotEmail)
      .then(resp => {
        this.postForgotPasswordActions();
        this.props.hideLoading();
      })
      .catch(error => {
        console.log("forgotPassword error:", error.response);
        this.props.hideLoading();
        if (
          !!error.response &&
          !!error.response.info &&
          !!error.response.info.message
        ){
          this.props.alert(error.response.info.message.replace(/\[(.*?)\]/g, ""));
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        } else {
          this.props.alert(error.message);
          this.setState({
            isError: true
          });
          window.scrollTo(0, 0);
        }
      });
  }

  postForgotPasswordActions = () => {
    this.setState({
      username: "",
      password: "",
      otpId: "",
      otp: "",
      otpMobileNo: "",
      forgotEmail: "",
      forgotEmailSent: true,
      formView: "login",
      isError: false
    });
  }

  render() {
    const ABMB_icon = require("assets/img/ABMB_icon.png");
    const Banner_bg = require("assets/img/pages/login/login-banner.jpg");
    const Dev_icon = require("assets/img/pages/login/dev-icon.svg");
    const User_icon = require("assets/img/pages/login/user-icon.svg");
    return (
      <React.Fragment>
        <div
          className={loginStyles.main}
          style={
            this.props.isAlertOpen()
              ? { minHeight: "calc(100vh - 47px)" }
              : null
          }
        >
          <AppHeader className={headerStyle.disabledFixedPosition}>
            <div className="container flush-max-width--">
              <Nav className={headerStyle.nav + " mr-auto"}>
                <img
                  src={ABMB_icon}
                  className={headerStyle.logo}
                  alt="Alliance Logo"
                />
                <div className={headerStyle.logoName}>ALLIANCE BANK</div>
                <div className={headerStyle.logoSubtitle}>
                  VIRTUAL CREDIT CARD PARTNER PORTAL{" "}
                  <span
                    className={headerStyle.logoVersion}
                  >{`V ${Environment.appVersion}`}</span>
                </div>
              </Nav>
              <Nav className={headerStyle.nav + " ml-auto"}>
                <div className={headerStyle.help}>
                  <a
                    href="javascript:;"
                    onClick={() => this.setState({ showHelpGuide: true })}
                  >
                    Help
                  </a>
                </div>
                {this.state.showHelpGuide && (
                  <HelpGuide
                    closeGuide={() => this.setState({ showHelpGuide: false })}
                  />
                )}
              </Nav>
            </div>
          </AppHeader>
          {!!this.state.forgotEmailSent ? (
            <SCToast 
              title={"Success!"}
              body={"Temporary password has been sent."}
              onClose={()=>{this.setState({forgotEmailSent: false})}}
            />
          ): null}
          <div className={loginStyles.section}>
            <div className={loginStyles.banner}>
              <div
                className={loginStyles.bannerBg}
                style={{ backgroundImage: `url(${Banner_bg})` }}
              ></div>
              <div className={loginStyles.bannerContent}>
                <div className={"container flush-max-width--"}>
                  <p>
                    Virtual Credit Card Partner Portal
                    <span>{`V ${Environment.appVersion}`}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={loginStyles.form}>
              {/* Select your role form */}
              {this.state.formView === "role" && (
                <div className={loginStyles.formCard}>
                  <div
                    className={`${loginStyles.formCardHeader} ${loginStyles.lessPB}`}
                  >
                    <h1>Welcome!</h1>
                    <p>Select your user role to begin.</p>
                  </div>
                  <Form onSubmit={this.onSubmit}>
                    <div
                      className={`${loginStyles.inputGroup} ${loginStyles.lessPB}`}
                    >
                      <Label>I am a</Label>
                    </div>
                    <div
                      className={`${loginStyles.inputGroup} ${loginStyles.fdRow}`}
                    >
                      <Label
                        check
                        className={
                          this.state.loginAs === "dev"
                            ? `${loginStyles.radioBlock} ${loginStyles.active}`
                            : loginStyles.radioBlock
                        }
                      >
                        <Input
                          id="pp-login-radio-dev"
                          type="radio"
                          name="loginAs"
                          value="dev"
                          onChange={this.inputOnchange}
                        />
                        <img src={Dev_icon} alt="Developer icon" />
                        Developer
                      </Label>
                      <Label
                        check
                        className={
                          this.state.loginAs === "user"
                            ? `${loginStyles.radioBlock} ${loginStyles.active}`
                            : loginStyles.radioBlock
                        }
                      >
                        <Input
                          id="pp-login-radio-user"
                          type="radio"
                          name="loginAs"
                          value="user"
                          onChange={this.inputOnchange}
                        />
                        <img src={User_icon} alt="Business user icon" />
                        Business User
                      </Label>
                    </div>

                    <div className={loginStyles.formCTA}>
                      <button
                        type="submit"
                        color="primary"
                        disabled={this.isDisabled()}
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                </div>
              )}

              {/* Login form */}
              {this.state.formView === "login" && (
                <div className={loginStyles.formCard}>
                  <div className={loginStyles.formCardHeader}>
                    <h1>Welcome!</h1>
                    <p>Login with your credentials below.</p>
                  </div>
                  <Form onSubmit={this.onSubmit}>
                    <div className={loginStyles.inputGroup}>
                      <Input
                        className={
                          this.state.isError ? loginStyles.error : null
                        }
                        onFocus={this.removeErrorWarning}
                        value={this.state.username}
                        onChange={this.inputOnchange}
                        id="pp-login-username"
                        name="username"
                        type="text"
                        placeholder="User ID"
                        autoComplete="username"
                      />
                      <Label for={"pp-login-username"}>User ID</Label>
                    </div>
                    <div className={loginStyles.inputGroup}>
                      <Input
                        className={
                          this.state.isError ? loginStyles.error : null
                        }
                        onFocus={this.removeErrorWarning}
                        value={this.state.password}
                        onChange={this.inputOnchange}
                        id="pp-login-password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <Label for={"pp-login-password"}>Password</Label>
                    </div>

                    <div className={loginStyles.formCTA}>
                      <button
                        type="submit"
                        color="primary"
                        disabled={this.isDisabled()}
                      >
                        LOG IN
                      </button>
                      <a 
                        href={"#"}
                        onClick={this.showForgotPassword}
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </Form>
                </div>
              )}

              {/* OTP form */}
              {this.state.formView === "otp" && (
                <div className={loginStyles.formCard}>
                  <div
                    className={`${loginStyles.formCardHeader} ${loginStyles.lessPB}`}
                  >
                    <h1>Mobile Verification</h1>
                  </div>
                  <div className={loginStyles.formMessage}>
                    We have sent an SMS with an OTP code to your registered
                    mobile number&nbsp;
                    {!!this.state.otpMobileNo ? (
                      <span>{this.state.otpMobileNo}&nbsp;</span>
                    ) : null}
                    for verification.
                  </div>
                  <Form onSubmit={this.onSubmit}>
                    <div className={loginStyles.inputGroup}>
                      <Input
                        className={
                          this.state.isError ? loginStyles.error : null
                        }
                        onFocus={this.removeErrorWarning}
                        value={this.state.otp}
                        onChange={this.inputOnchange}
                        id="pp-login-otp"
                        name="otp"
                        type="password"
                        placeholder="One-Time Password"
                        maxLength={6}
                        autoComplete="current-password"
                      />
                      <Label for={"pp-login-otp"}>
                        One-Time Password (OTP)
                      </Label>
                    </div>
                    <p>
                      Didn't receive?&nbsp;
                      <a
                        className={
                          !!this.state.otpResendLock
                            ? `${loginStyles.resendCta} ${loginStyles.disabled}`
                            : loginStyles.resendCta
                        }
                        onClick={this.loginActions}

                      >
                        RESEND CODE
                      </a>
                    </p>
                    <div
                      className={`${loginStyles.formCTA} ${loginStyles.twoCol}`}
                    >
                      <button type="submit" disabled={this.isDisabled()}>
                        VERIFY
                      </button>
                      <button
                        className={loginStyles.flushBg}
                        onClick={this.otpCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </div>
              )}

              {/* Forgot password */}
              {this.state.formView === "forgotPw" && (
                <div className={loginStyles.formCard}>
                  <div
                    className={`${loginStyles.formCardHeader} ${loginStyles.lessPB}`}
                  >
                    <h1>Forgot password?</h1>
                  </div>
                  <div className={loginStyles.formMessage}>
                    No worries! Enter your email address &nbsp;and we will send you a temporary password.
                  </div>
                  <Form onSubmit={this.onSubmit}>
                    <div className={loginStyles.inputGroup}>
                      <Input
                        className={
                          this.state.isError ? loginStyles.error : null
                        }
                        onFocus={this.removeErrorWarning}
                        value={this.state.forgotEmail}
                        onChange={this.inputOnchange}
                        id="pp-forgot-email"
                        name="forgotEmail"
                        type="text"
                        placeholder="Email"
                        autoComplete="forgotEmail"
                      />
                      <Label for={"pp-login-otp"}>
                        Email Address
                      </Label>
                    </div>
                    <div
                      className={`${loginStyles.formCTA} ${loginStyles.twoCol}`}
                    >
                      <button type="submit" disabled={this.isDisabled()}>
                        SEND EMAIL
                      </button>
                      <button
                        className={loginStyles.flushBg}
                        onClick={this.hideForgotPassword}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withLoading(withCustomAlert(Login));
