import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Card, CardBody, Row, Col } from 'reactstrap';
import './style.scss';
import Switch from "react-switch"

class CollapsableListItem extends React.PureComponent {

    constructor(props) {
        super(props)
    }

    renderDeleteButton = () =>{
        const { onDeleteButtonClick, shouldShowDeleteButton, deleteButton } = this.props;
        
        if(!shouldShowDeleteButton)
            return <React.Fragment/>;

        if(deleteButton)
            return deleteButton;
        
        return (
            <Button className="custom-secondary-btn" onClick={onDeleteButtonClick}>
                <i className="cui-trash icons font-2xl d-block"></i>
            </Button>   
        )
    }

    renderSwitchButton = () => {
        const { onSwitchButtonClick, shouldShowSwitchButton, switchButton, isActive } = this.props;

        if (!shouldShowSwitchButton)
            return <React.Fragment />;

        if (switchButton)
            return switchButton;

        return (
            <Switch onChange={onSwitchButtonClick} checked={isActive === 'A' ? true : false} />
        )
    }

    renderEditButton = () => {
        const { onEditButtonClick, shouldShowEditButton, editButton } = this.props;

        if(!shouldShowEditButton)
            return <React.Fragment/>

        if(editButton)
            return editButton;

        return (
            <Button className="custom-secondary-btn" onClick={onEditButtonClick}>
                <i className="cui-pencil icons font-2xl d-block"></i>
            </Button>   
        )
    }

    renderCollapseButton = () => {
        const { shouldEnableCollapse, shouldCollapseItem, onClickCollapse } = this.props;

        if(!shouldEnableCollapse)
            return null;

        return (
            <React.Fragment>
                <Button className="custom-secondary-btn" onClick={onClickCollapse}>
                    {shouldCollapseItem?<i className="fa fa-sort-asc fa-lg"/>:<i className="fa fa-sort-desc fa-lg"/>}
                </Button>
            </React.Fragment>
        )
    }

    renderCollapseItem = () => {
        const { shouldEnableCollapse, shouldCollapseItem, collapseItemContent } = this.props;

        if(!shouldCollapseItem)
            return null;
        
        return(
            <Row className="collapse-item-container">
                <Col>
                    <Collapse isOpen={shouldCollapseItem}>
                        { collapseItemContent ? 
                            collapseItemContent: (
                                <Row className="default-collapse-item">
                                    <p>This is a testing collapse item...</p>
                                </Row>
                            )
                        }
                    </Collapse>
                </Col>
            </Row>
        )
    }

    render() {
        const { title, titleFront } = this.props;

        return (
            <div className="list-item">
                <Row className="list-item-container">
                    <Col sm="9" className="left-item-wrap">
                        <Row>
                            <Col><div><span className={this.props.customClass? this.props.customClass : "title-front"}>{titleFront}</span><span className={this.props.customClass? this.props.customClass : "title-wrap"}>{title}</span></div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="3" className="right-item-wrap">
                        <Row style={{ height: '100%', justifyContent:'space-around', alignItems:'center' }}>
                            <div>{this.renderSwitchButton()}</div>
                            <div>{this.renderDeleteButton()}</div>
                            <div>{this.renderEditButton()}</div>
                            <div>{this.renderCollapseButton()}</div>
                        </Row>
                    </Col>
                </Row>
                { this.renderCollapseItem() }
            </div>
        )
    }
}

CollapsableListItem.propTypes = {
    title: PropTypes.string.isRequired, 
    shouldShowSwitchButton: PropTypes.bool,
    shouldShowDeleteButton: PropTypes.bool, 
    shouldShowEditButton: PropTypes.bool, 
    shouldEnableCollapse: PropTypes.bool, 
    shouldCollapseItem: PropTypes.bool, 
    switchButton: PropTypes.element, 
    deleteButton: PropTypes.element, 
    editButton: PropTypes.element,
    collapseItemContent: PropTypes.element, 
    onSwitchButtonClick: PropTypes.func, 
    onDeleteButtonClick: PropTypes.func, 
    onEditButtonClick: PropTypes.func, 
    onClickCollapse: PropTypes.func 
};

CollapsableListItem.defaultProps = {
    title: 'This is default title of list item',
    shouldShowSwitchButton: true,
    shouldShowDeleteButton: true,
    shouldShowEditButton: true,
    shouldEnableCollapse: true,
    shouldCollapseItem: true,
    switchButton: null,
    deleteButton: null,
    editButton: null,
    collapseItemContent: null,
    onSwitchButtonClick: () => { console.log('this is default switch button action') },
    onDeleteButtonClick: () => { console.log('this is default delete button action') },
    onEditButtonClick: () => { console.log('this is default edit button action') },
    onClickCollapse: () => { console.log('this is default collapse button action') }
};

export default CollapsableListItem;