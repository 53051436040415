import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  InputGroup,
  Row,
  Nav
} from "reactstrap";
import { SessionService } from "services";
import { withCustomAlert } from "hoc";
import { withLoading } from "hoc";
import loginStyles from "../Login/login.module.scss";
import headerStyle from "../../../containers/DefaultLayout/header.module.scss";
import { AppHeader } from "@coreui/react";
import { Footer } from "components";
import Environment from "../../../environment";
import HelpGuide from "../../../containers/DefaultLayout/HelpGuide";

import { Webservice } from "../../../services";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      isError: false,
      showHelpGuide: false
    };
  }

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.error === "InvalidSession"
    ) {
      this.props.alert("Invalid Session, Please login to continue");
    }
  }

  inputOnchange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    const { password, newPassword, confirmNewPassword } = this.state;
    this.props.showLoading();

    let RSA = new window.JSEncrypt();
    RSA.setPublicKey(Environment.rsaKey);
    let encryptedPassword = RSA.encrypt(password, true);
    let encryptedNewPassword = RSA.encrypt(newPassword, true);

    if (newPassword !== confirmNewPassword){
      this.props.alert("New password and Confirm New Password must be the same");
      this.props.hideLoading();
    } else {
      Webservice.applications.updateTempPassword(this.props.location.state.username,encryptedPassword,encryptedNewPassword)
      .then(resp => {
        console.log("changed successfully");

        // after update temp password, it's good to proceed login
        SessionService.login(this.props.location.state.username, newPassword)
          .then(resp => {
            console.log("login successfully");

            this.props.hideLoading();

            if (!!resp.otpRequired) {
              this.props.navigation.pushTo("/login", "", {
                username: this.props.location.state.username,
                password: newPassword,
                otpId: resp.otpId,
                mobileNo: resp.mobile
              });
            } else {
              this.props.navigation.replace("/home/");
            }
          })
          .catch(error => {
            console.log("login:error:", error);

            this.props.alert(error.message);

            this.setState({
              isError: true
            });
            
            this.props.hideLoading();
          });
      })
      .catch(error => {
        console.log("changedPassword:error:", error);

        this.props.alert(error.message);

        this.setState({
          isError: true
        });
        
        this.props.hideLoading();
      });
    }



    event.preventDefault();
  };

  removeErrorWarning = () => {
    this.setState({
      isError: false
    });
  };
  render() {
    const ABMB_icon = require("assets/img/ABMB_icon.png");
    const Banner_bg = require("assets/img/pages/login/login-banner.jpg");
    return (
      <React.Fragment>
        <div className={loginStyles.main} style={this.props.isAlertOpen() ? {minHeight:'calc(100vh - 47px)'} : null}>
          <AppHeader className={headerStyle.disabledFixedPosition}>
            <div className="container flush-max-width--">
              <Nav className={headerStyle.nav + " mr-auto"}>
                <img
                  src={ABMB_icon}
                  className={headerStyle.logo}
                  alt="Alliance Logo"
                />
                <div className={headerStyle.logoName}>ALLIANCE BANK</div>
                <div className={headerStyle.logoSubtitle}>
                  VIRTUAL CREDIT CARD PARTNER PORTAL{" "}
                  <span
                    className={headerStyle.logoVersion}
                  >{`V ${Environment.appVersion}`}</span>
                </div>
              </Nav>
              <Nav className={headerStyle.nav + " ml-auto"}>
                <div className={headerStyle.help}>
                  <a
                    href="javascript:;"
                    onClick={() => this.setState({ showHelpGuide: true })}
                  >
                    Help
                  </a>
                </div>
                {this.state.showHelpGuide && (
                  <HelpGuide
                    closeGuide={() => this.setState({ showHelpGuide: false })}
                  />
                )}
              </Nav>
            </div>
          </AppHeader>
          <div className={loginStyles.section}>
            <div className={loginStyles.banner}>
              <div
                className={loginStyles.bannerBg}
                style={{ backgroundImage: `url(${Banner_bg})` }}
              ></div>
              <div className={loginStyles.bannerContent}>
                <div className={"container flush-max-width--"}>
                  <p>
                    Virtual Credit Card Partner Portal
                    <span>v1.0.0</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={loginStyles.form}>
              <div className={loginStyles.formCard}>
                <div className={loginStyles.formCardHeader}>
                  <h1>Reset Password</h1>
                </div>
                <Form onSubmit={this.onSubmit}>
                  <div className={loginStyles.inputGroup}>
                    <Input
                      className={
                        this.state.isError
                          ? loginStyles.error 
                          : null
                      }
                      onFocus={this.removeErrorWarning}
                      value={this.props.location.state.username}
                      onChange={this.inputOnchange}
                      id="pp-login-username"
                      name="username"
                      type="text"
                      placeholder="User ID"
                      autoComplete="username"
                      readOnly
                    />
                    <Label for={"pp-login-username"}>User ID</Label>
                  </div>
                  
                  <div className={loginStyles.inputGroup}>
                    <Input
                      className={
                        this.state.isError
                          ? loginStyles.error 
                          : null
                      }
                      onFocus={this.removeErrorWarning}
                      value={this.state.password}
                      onChange={this.inputOnchange}
                      id="pp-login-password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                    <Label for={"pp-login-password"}>Current Password</Label>
                  </div>
                  <div className={loginStyles.inputGroup}>
                    <Input
                      className={
                        this.state.isError
                          ? loginStyles.error 
                          : null
                      }
                      onFocus={this.removeErrorWarning}
                      value={this.state.newPassword}
                      onChange={this.inputOnchange}
                      id="pp-login-newPassword"
                      name="newPassword"
                      type="password"
                      placeholder="Enter New Password"
                      autoComplete="current-newPassword"
                    />
                    <Label for={"pp-login-newPassword"}>New Password</Label>
                  </div>
                  <div className={loginStyles.inputGroup}>
                    <Input
                      className={
                        this.state.isError
                          ? loginStyles.error 
                          : null
                      }
                      onFocus={this.removeErrorWarning}
                      value={this.state.confirmNewPassword}
                      onChange={this.inputOnchange}
                      id="pp-login-confirmNewPassword"
                      name="confirmNewPassword"
                      type="password"
                      placeholder="Enter Confirm New Password"
                      autoComplete="current-confirmNewPassword"
                    /> 
                    <Label for={"pp-login-confirmNewPassword"}>Confirm New Password</Label>
                  </div>
                  <div className={loginStyles.formCTA}>
                    <button type="submit" color="primary">CHANGE PASSWORD</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withLoading(withCustomAlert(ChangePassword));
