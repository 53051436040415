import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, FormGroup } from 'reactstrap';
import './Dropdown.scss';
import PropTypes from 'prop-types';

class SCDropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toogle = () => {
        this.setState((prevState) => {
            return {
                isOpen: !prevState.isOpen
            }
        })
    }

    onChange = (e) => {

        console.log('onchange in dropdown');

        let value = e.currentTarget.value;
        let name = e.currentTarget.name;
        let selectedOption = {
            value: value, label: name
        }

        this.props.onChange(selectedOption)
    }

    render() {
        return (
            <FormGroup>
                {this.props.isShowLabel?(<Label className="font">FILTER</Label>):(null)}
                <Dropdown isOpen={this.state.isOpen} toggle={this.toogle} disabled={this.props.disabled}>
                    <DropdownToggle caret className={this.props.customizedStyle ? this.props.customizedStyle : "dropdownToggle"} disabled={this.props.disabled}>
                        {this.props.selectedOption ? this.props.selectedOption.label : 'Please select'}
                    </DropdownToggle>
                    <DropdownMenu className="scrollable-menu">
                        {
                            this.props.options.map(option => {
                                return (<DropdownItem key={option.value} onClick={this.onChange} value={option.value} name={option.label}>{option.label}</DropdownItem>)
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>

        )
    }
}

SCDropDown.propTypes = {
    onChange:PropTypes.func.isRequired,
    selectedOption:PropTypes.object,
    options:PropTypes.array,
    isShowLabel:PropTypes.bool,
    disabled: PropTypes.bool
};

SCDropDown.defaultProps = {
    onChange:()=>{},
    selectedOption:null,
    options:[],
    isShowLabel:true,
    disabled:false
};

export default SCDropDown;