import React, { Component, createRef } from "react";
import "./FileInput.scss";
import PropTypes from "prop-types";

// icon
import { ReactComponent as UploadLogo } from "./UploadIcon.svg";
import { ReactComponent as FileLogo } from "./FileIcon.svg"

class BPPFileInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            selectedFile: null,
            errorMsg: "",
            dropZoneRef: createRef(),
            inputRef: createRef()
        };
    }

    componentDidMount() {
        this.inputInit();
    }

    componentWillUnmount() {
        this.inputUnmount();
    }

    inputInit = () => {
        let dropZone = this.state.dropZoneRef.current;
        dropZone.addEventListener("dragenter", this.handleDragIn);
        dropZone.addEventListener("dragleave", this.handleDragOut);
        dropZone.addEventListener("dragover", this.handleDragOver);
        dropZone.addEventListener("drop", this.handleDrop);
    };

    inputUnmount = () => {
        let dropZone = this.state.dropZoneRef.current;
        dropZone.removeEventListener("dragenter", this.handleDragIn);
        dropZone.removeEventListener("dragleave", this.handleDragOut);
        dropZone.removeEventListener("dragover", this.handleDragOver);
        dropZone.removeEventListener("drop", this.handleDrop);
    };

    handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: true });
    };

    handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });
    };

    handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({errorMsg: ""});
        let selectedFiles = !!e.dataTransfer
            ? e.dataTransfer.files
            : e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            let selectedFile = selectedFiles[0];
            let validFile = true;
            if (
                !!this.props.fileMaxSize &&
                selectedFile.size > this.props.fileMaxSize
            ) {
                validFile = false;
                this.setState({errorMsg: "The selected file size("+ (selectedFile.size / 1048576).toFixed(2) +"MB) exceeded the maximum file size(" + (this.props.fileMaxSize / 1048576).toFixed(2) + "MB)"});
            }
            if (
                !!this.props.fileTypes &&
                this.props.fileTypes.length > 0 &&
                !this.props.fileTypes.includes(selectedFile.type)
            ) {
                validFile = false;
                this.setState({errorMsg: "Only files with the following extensions are allowed: " + this.props.fileTypes});
            }
            if (validFile) {
                this.onDrop(selectedFile);
            }
        }
        this.setState({ dragging: false });
    };

    onDrop = file => {
        this.setState({selectedFile : file});
        if (!!this.props.onChange) {
            this.props.onChange(file);
        }
    };

    render() {
        return (
            <div 
                id={!!this.props.id ? this.props.id : "file-input"}
                className={this.props.invalid || !!this.state.errorMsg ? "fileInput invalid--" : "fileInput"}
            >
                <div
                    className={!this.state.dragging ? "dropZone" : "dropZone active--"}
                >
                    <div className={"dropZone-action"}
                        ref={this.state.dropZoneRef}
                        onClick={e => {
                            this.state.inputRef.current.click();
                        }}
                    ></div>
                    {!!!this.state.selectedFile ? (
                        <div className={"dropZone-content"}>
                            <UploadLogo />
                            <p>
                                Drag files here to upload or &nbsp;
                                <span>
                                    select a file
                                </span>
                                &nbsp;from your device
                            </p>
                        </div>
                    ) : (
                        <div className={"dropZone-content"}>
                            <FileLogo/>
                            <p>
                                {this.state.selectedFile.name}
                            </p>
                        </div>
                    )}
                    <input
                        className={"dropZone-fileInput"}
                        ref={this.state.inputRef}
                        type={"file"}
                        onChange={e => {
                            this.handleDrop(e);
                        }}
                    />
                </div>
                {!!this.state.errorMsg && (
                    <div className={"dropZone-errorMsg"}>
                        {this.state.errorMsg}
                    </div>
                )}
            </div>
        );
    }
}

BPPFileInput.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    fileMaxSize: PropTypes.number,
    fileTypes: PropTypes.array,
    invalid: PropTypes.bool
};

BPPFileInput.defaultProps = {
    id: "",
    onChange: () => { },
    fileMaxSize: 1048576,
    fileTypes: ["image/png", "image/jpeg"],
    invalid: false
};

export default BPPFileInput;
