import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
//import './FeatureUpdatedModal.scss';
import featureUpdatedList from'./featureUpdatedList.js'

class FeatureUpdatedModal extends Component {

    getContent = (feature) =>{
        let content =[]
        for( let i=0; i < feature.length; i++){
         content[i] =  this.renderContent(feature[i])
        
            
        }
        return content
    }

    renderContent (content) {
      return (<p className="content" > {content}</p>)
    }

    render() {
        return (
            <Modal size='lg' isOpen={this.props.toggleModal}>
                <ModalHeader toggle={this.props.onToggle}>What's New?</ModalHeader>
                <ModalBody>
                    <div>
                        <h4 className="featureUpdatedTitle">New Features</h4>
                        {
                       featureUpdatedList.find(feature=>feature.label==="newFeatures").value.length > 0 ?
                        (<p>{this.getContent(featureUpdatedList.find(feature=>feature.label==="newFeatures").value)}</p>) :null
                        }
                    </div>
                    <div>
                        <h4 className="featureUpdatedTitle">For Notification Only</h4>
                        {
                       featureUpdatedList.find(feature=>feature.label==="notification").value.length > 0 ?
                        (<p>{this.getContent(featureUpdatedList.find(feature=>feature.label==="notification").value)}</p>) :null
                        }
                    </div>
                    <div>
                    <h4 className="featureUpdatedTitle">Bug Fixes</h4>
                        {
                       featureUpdatedList.find(feature=>feature.label==="newBugFix").value.length > 0 ?
                        (<p>{this.getContent(featureUpdatedList.find(feature=>feature.label==="newBugFix").value)}</p>) :null
                        }
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default FeatureUpdatedModal;

