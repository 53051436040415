import React, { Component } from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, ChangePassword, Page404, Page500 } from './views/Pages';

// import { renderRoutes } from 'react-router-config';

import { NavRoute } from 'core';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <NavRoute key="404" exact path="/404" name="Page 404" component={Page404} />
          <NavRoute key="500" exact path="/500" name="Page 500" component={Page500} />
          <NavRoute key="home" path="/home" name="Home" component={DefaultLayout} />
          <NavRoute key="changePassword" exact path="/changePassword" name="ChangePassword" component={ChangePassword} />
          <NavRoute key="login" name="Login Page" component={Login} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
