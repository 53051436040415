import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, FormGroup } from 'reactstrap';
import './style.scss';
import PropTypes from 'prop-types';

class ApplicantSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toogle = () => {
        this.setState((prevState) => {
            return {
                isOpen: !prevState.isOpen
            }
        })
    }

    onChange = (e) => {

        console.log('onchange in dropdown');

        let value = e.currentTarget.value;
        let name = e.currentTarget.name;
        let selectedOption = {
            value: value, label: name
        }

        this.props.onChange(selectedOption)
    }

    render() {
        return (
            <div className="applicant-dropdown-container">
                <FormGroup>
                    <Dropdown isOpen={this.state.isOpen} toggle={this.toogle}>
                        <DropdownToggle caret className="applicant-selector-dropdown-toggle">
                            {this.props.selectedOption ? this.props.selectedOption.label : 'Please select'}
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                this.props.options.map(option => {
                                    return (
                                    <DropdownItem key={option.value} onClick={this.onChange} value={option.value} name={option.label} className='applicant-selector-item'>
                                        {option.label}
                                    </DropdownItem>)
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>
                </FormGroup>
            </div>
        )
    }
}

ApplicantSelector.propTypes = {
    onChange:PropTypes.func.isRequired,
    selectedOption:PropTypes.object,
    options:PropTypes.array
};

ApplicantSelector.defaultProps = {
    onChange:()=>{},
    selectedOption:null,
    options:[],
};

export default ApplicantSelector;