import React, { Component } from 'react';
import { FormGroup, Input, InputGroup, Button, InputGroupAddon, Form } from 'reactstrap';
import PropTypes from 'prop-types';

class Search extends Component {

    searchHandler = (e) => {
        this.props.onChange(e.target.value);
    }

    onClick = (e) => {
        this.props.onClickButton();
        e.preventDefault();
    }
    render() {
        return (
            <Form onSubmit={this.onClick}>
                <FormGroup>
                    <InputGroup>
                        <Input id="inputSearch" name="inputSearch" type="text" placeholder={this.props.placeholder||"Search"} value={this.props.value} onChange={this.searchHandler} autoComplete={this.props.autoComplete ? this.props.autoComplete : 'on'}/>
                        {
                            !!this.props.displaySearchButton ? (
                                <InputGroupAddon addonType="append">
                                    <Button type="submit" style={this.props.customSearchButtonStyle ? this.props.customSearchButtonStyle : { marginLeft: 0, backgroundColor: '#f5f5f5' }} onClick={this.onClick} >
                                        <i className="fa fa-search"></i>
                                    </Button>
                                </InputGroupAddon>
                            ) : ('')
                        }
                    </InputGroup>
                </FormGroup>
            </Form>
        )
    }
}

Search.propTypes = {
    displaySearchButton:PropTypes.bool,
};

Search.defaultProps = {
    displaySearchButton: true
};

export default Search;