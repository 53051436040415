import React, { Component } from 'react';
import { Button, Label, Input } from 'reactstrap';
import './style.scss';

class CircleRadioButton extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div style={{ flexDirection: 'row' }}>
                <Input type="radio" name={this.props.name} onClick={this.props.onClick} />
                <Label style={{ color: this.props.color }}><b>{this.props.button.label1}</b>{this.props.button.label}</Label>
            </div>
        );
    }
}

CircleRadioButton.defaultProps = {
    color: 'black'
}

export default CircleRadioButton;