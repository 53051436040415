import React, { Component } from 'react';
import { Popover } from 'reactstrap';

class DbosPopover extends Component {

  render() {
    return (
      <span>
        <Popover className={this.props.className} placement={this.props.placement} isOpen={this.props.isOpen} target={this.props.id} toggle={this.props.toggle}>
          {this.props.children}
        </Popover>
      </span>
    );
  }
}

export default DbosPopover;