import React, { Component } from 'react';
import { Button, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppSidebarToggler } from '@coreui/react';
import ABMB_icon from '../../assets/img/ABMB_icon.png';
import headerStyles from './header.module.scss';
import { DbosPopover } from 'components';
import { SessionService } from 'services';
import Environment from '../../environment';
import HelpGuide from './HelpGuide';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenPopover: false,
      showHelpGuide: false
    }
  }

  onToggleOptionButton = () => {
    this.setState(prevState => {
      return {
        isOpenPopover: !prevState.isOpenPopover
      }
    })
  }

  onLogout = () => {
    
    SessionService.logout().then(resp => {
      console.log('logout successfully:', resp);
      SessionService.clearSession();
    }).catch(error => {
      console.log('logout failed:', error);
      SessionService.clearSession();
    })

    this.props.onLogout();
  }

  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className={headerStyles.nav + " mr-auto"}>
          <img src={ABMB_icon} className={headerStyles.logo} alt="AllianceLogo" />
          <div className={headerStyles.logoTextContainer}>
            <div className={headerStyles.logoName}>ALLIANCE BANK</div>
            <div className={headerStyles.logoSubtitle}>VIRTUAL CREDIT CARD PARTNER PORTAL <span className={headerStyles.logoVersion}>{`V ${Environment.appVersion}`}</span></div>
          </div>
        </Nav>
        <Nav className={headerStyles.nav + " ml-auto"}>
          <div className={headerStyles.help}>
            <a href="javascript:;" onClick={() => this.setState({ showHelpGuide: true })}>Help</a>
          </div>
          {this.state.showHelpGuide && <HelpGuide closeGuide={() => this.setState({ showHelpGuide: false })} />}
          <div className={headerStyles.userNameContainer}>
            <div className={headerStyles.userName}>{this.props.username}</div>
            <div className={headerStyles.userNameSubtitle}>CUSTOMER SERVICE REPRESENTATIVE</div>
          </div>
          <Button className={headerStyles.transparentBtn} onClick={this.onToggleOptionButton} id="header-popover">
            <i className="fa fa-file-text-o  fa-lg"></i>
          </Button>
          <DbosPopover placement="bottom" isOpen={this.state.isOpenPopover} id="header-popover" toggle={this.onToggleOptionButton}>
            <Button className={headerStyles.logoutBtn} onClick={this.onLogout}>
              <span class="cui-account-logout" aria-hidden="true"></span> Logout
            </Button>
          </DbosPopover>
        </Nav>
      </React.Fragment>
    );
  }
}


DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
