import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div>Loading...</div>;
}

const Vcc = Loadable({
  loader: () => import("./views/Pages/Applications/VCC"),
  loading: Loading
});

const Fastcash = Loadable({
  loader: () => import("./views/Pages/Applications/FastCash"),
  loading: Loading
});

const UserList = Loadable({
  loader: () => import("./views/Pages/Applications/UserMgm/UserList"),
  loading: Loading
});
const UserDetails = Loadable({
  loader: () => import("./views/Pages/Applications/UserMgm/UserDetails"),
  loading: Loading
});
const InApp = Loadable({
  loader: () => import("./views/Pages/Applications/InApp"),
  loading: Loading
});
const CustomiseSdk = Loadable({
  loader: () => import("./views/Pages/Applications/CustomiseSdk"),
  loading: Loading
});

// const Dashboard = Loadable({
//   loader: () => import('./views/Dashboard'),
//   loading: Loading,
// });

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const landingRoutes = [
  { path: '/home', exact:true, name: 'Home', component: Vcc },//entry components
  // { path: '/home/dashboard', exact:true, name: 'Dashboard', component: Dashboard },
]

const applicationsRoutes = [
  { path: '/home/vcc/', exact: true, name: 'Virtual Credit Card', component: Vcc },
  { path: '/home/fastcash/', exact: true, name: 'Fast Cash', component: Fastcash },
  { path: '/home/usermgm/', exact: true, name: 'User Management', component: UserList },
  { path: '/home/usermgm/userDetails', exact: false, name: 'User Management', component: UserDetails },
  { path: '/home/inApp', exact: true, name: 'In-App Purchases Transaction History', component: InApp },
  { path: '/home/customiseSdk', exact: true, name: 'Customise SDK', component: CustomiseSdk}
]

const routes = 
  [
    ...landingRoutes,
    ...applicationsRoutes,
  ];

export default routes;
