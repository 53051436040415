import { withCustomAlert, withLoading, withSidebarRefreshHandler } from "hoc";
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import moment from "moment";
import { Webservice } from "../../../../services";
import { 
  BackIcon, 
  CloseIcon, 
  TooltipIcon, 
  DefaultLogo,
  ToastTickIcon,
  WarnIcon,
  colorSelectionInit, 
  logoPreviewInit, 
  logoPreviewErrorMsg,
  formStatus
} from "./formModal";
import commonStyles from "../styles.module.scss";
import sdkThemeStyles from "./styles.module.scss";
// Component
import { BPPFileInput } from "../../../../components";
import SDKPreview from "./components/sdkPreview";
// Color Picker
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const SdkCustomisation = props => {

  const [logoPreview, setLogoPreview] = useState(logoPreviewInit);
  const [logoPreviewErrors, setLogoPreviewErrors] = useState(logoPreviewErrorMsg);
  const [openModal, setOpenModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(formStatus.new);
  const [isPriColorPickerOpen, setIsPriColorPickerOpen] = useState(false);
  const [isSecColorPickerOpen, setIsSecColorPickerOpen] = useState(false);
  const [isFormColorPickerOpen, setIsFormColorPickerOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [priColor, setPriColor] = useColor("hex", "#002659");
  const [secColor, setSecColor] = useColor("hex", "#D71920");
  const [formColor, setFormColor] = useColor("hex", "#002659");
  const priColorPickerRef = useRef(null);
  const secColorPickerRef = useRef(null);
  const formColorPickerRef = useRef(null);
  const [tooltipToggle, setTooltipToggle]  =  useState(false);
  const [lastModifiedInfo, setLastModifiedInfo] = useState({});
  

  const handleClickOutsideColorPick = (event) => {
    if (!!priColorPickerRef.current && !priColorPickerRef.current.contains(event.target)) {
      closeColorPicker("pri");
    }
    if (!!secColorPickerRef.current && !secColorPickerRef.current.contains(event.target)) {
      closeColorPicker("sec");
    }
    if (!!formColorPickerRef.current && !formColorPickerRef.current.contains(event.target)) {
      closeColorPicker("form");
    }
  }

  useEffect(()=>{
    document.addEventListener('mousedown', handleClickOutsideColorPick);
    getThemeColors();
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideColorPick);
    };
  },[]);

  const getThemeColors = () => {
    props.showLoading();
    Webservice.applications.getThemeColors()
      .then(response => {
        let isPendingApproval = 
          !!response.approvalPartnerSdkSettings &&
          Object.keys(response.approvalPartnerSdkSettings).length > 0;
        if(isPendingApproval || !!response.primaryBackgroundColour) {
          let settings = isPendingApproval
            ? response.approvalPartnerSdkSettings 
            : response;
          let imageKey = isPendingApproval
            ? "approvalPartnerImages"
            : "partnerImages";
          console.log("@resp", settings);
          setPriColor(
            !!settings.primaryBackgroundColour
              ? toColor("hex",settings.primaryBackgroundColour)
              : toColor("hex","#002659")
          );
          setSecColor(
            !!settings.secondaryBackgroundColour
              ? toColor("hex",settings.secondaryBackgroundColour)
              : toColor("hex","#D71920")
          );
          setFormColor(
            !!settings.formElementColour
              ? toColor("hex",settings.formElementColour)
              : toColor("hex","#002659")
          );
          setLastModifiedInfo({
            name: settings.lastModifiedBy,
            date: settings.lastModifiedOn
          });
          if(!!settings[imageKey]){
            parseImage(
              settings[imageKey].imageName,
              settings[imageKey].imageContentType,
              settings[imageKey].image
            );
          }
          if (!!settings.approvalStatus) {
            setApprovalStatus(settings.approvalStatus);
          }else{
            setApprovalStatus(formStatus.approved);
          }
          props.hideLoading();
        } else {
          setApprovalStatus(formStatus.new);
          props.hideLoading();
        }
      })
      .catch(error => {
        console.log("get theme color failed error: ", error);
        if (error.response && error.response.error) {
          props.alert(error.response.error);
          props.hideLoading();
        }
      });
  }

  const handleColorPickerChange = (event,target) => {
    switch (target) {
      case "pri":
        setPriColor(event);
        break;
      case "sec":
        setSecColor(event);
        break;
      case "form":
        setFormColor(event);
        break;
      default:
        break;
    }
  };

  const parseImage = (name, type, base64) => {
    let loadImage = { ...logoPreviewInit };
    let rawSize = base64.replace(/^.*base64,(?=\/)/, "").length;

    loadImage.name = name;
    loadImage.type = type;
    loadImage.size = 4 * Math.ceil(rawSize / 3) * 0.5624896334383812;
    loadImage.base64 = base64;

    let imgPreload = new Image();
    imgPreload.src = base64;
    imgPreload.onload = () => {
      loadImage.dimWidth = imgPreload.width;
      loadImage.dimHeight = imgPreload.height;
      setLogoPreview(loadImage);
      props.hideLoading();
    };
    imgPreload.onerror = () => {
      console.log('imgPreload error');
      window.scrollTo(0, 0);
      props.alert("The selected image appears to be corrupted. Please upload again.");
      setLogoPreview(loadImage);
      setTimeout(()=>{
        let logoInfo = { ...logoPreviewInit };
        setLogoPreview(logoInfo);
      }, 500)
      props.hideLoading();
    }
  };

  const logoPreviewOnChange = file => {
    props.showLoading();
    props.onDismiss();
    let reader = new FileReader();
    let logoInfo = { ...logoPreview };
    reader.readAsDataURL(file);
    reader.onload = function() {
      parseImage(file.name, file.type, reader.result);

      let errors = { ...logoPreviewErrors };
      if (!!errors["logoImg"]) {
        errors["logoImg"] = "";
        setLogoPreviewErrors(errors);
      }
    };
    reader.onerror = function() {
      console.log('logoPreviewOnChange error');
      props.hideLoading();
    }
  };

  const logoPreviewOnCancel = () => {
    toggleModal();
    setModalContent({
      header: "Are you sure you want to delete this logo image?",
      content: "",
      actionCallback: () => {
        let logoInfo = { ...logoPreviewInit };
        setLogoPreview(logoInfo);
      }
    });
  };

  const backBtnToggle = () => {
    toggleModal();
    setModalContent({
      header: "Leave Page?",
      content: "Any changes made will be lost. Are you sure you want to leave the page without submitting?",
      actionCallback: () => {
        props.navigation.pushTo("/home/");
      }
    });
  };
  
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const openColorPicker = (target) => {
    switch (target) {
      case "pri":
        setIsPriColorPickerOpen(true);
        break;
      case "sec":
        setIsSecColorPickerOpen(true);
        break;
      case "form":
        setIsFormColorPickerOpen(true);
        break;
      default:
        setIsPriColorPickerOpen(true);
        break;
    }
  }

  const closeColorPicker = (target) => {
    switch (target) {
      case "pri":
        setIsPriColorPickerOpen(false);
        break;
      case "sec":
        setIsSecColorPickerOpen(false);
        break;
      case "form":
        setIsFormColorPickerOpen(false);
        break;
      default:
        setIsPriColorPickerOpen(false);
        break;
    }
  }

  const resetColors = () => {
    setPriColor(toColor("hex","#002659"));
    setSecColor(toColor("hex","#D71920"));
    setFormColor(toColor("hex","#002659"));
  }

  const onSubmit = () => {
    if(!!logoPreview.base64){
      let payload = {
        primaryBackgroundColour: priColor.hex,
        secondaryBackgroundColour: secColor.hex,
        formElementColour: formColor.hex,
        approvalPartnerImages: { 
          imageType: "L",
          image: logoPreview.base64,
          imageContentType: logoPreview.type,
          imageName: logoPreview.name
        }
      }
      console.log("onSubmit payload", payload);
      Webservice.applications.postThemeColors(payload)
        .then(resp => {
          console.log("successful");
          setOpenToast(true);
          setApprovalStatus(formStatus.pendingApproval);
          getThemeColors();
        })
        .catch(error => {
          console.log("post theme color failed error: ", error);
          if (error.response && error.response.error) {
            window.scrollTo(0, 0);
            props.alert(error.response.error);
            props.hideLoading();
          }
        });
    } else {
      let errors = {};
      errors.logoImg = "Logo is required.";
      setLogoPreviewErrors(errors);
    }
  }

  const hexToRgb = (hex) => {
    let color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let colorInRGB = !!color ? {
      r: parseInt(color[1], 16),
      g: parseInt(color[2], 16),
      b: parseInt(color[3], 16)
    } : null;

    return colorInRGB;
  }
  const rgbToHex = (rgb) => {
    return "#" + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
  }
  const isLowGamma = (hex) => {
    let colorInRGB = hexToRgb(hex)
    if(!!colorInRGB){
      let gammaVal = colorInRGB.r*0.299 + colorInRGB.g*0.587 + colorInRGB.b*0.114;
      return gammaVal < 186;
    }
    return false;
  }
  const lighten = (hex,value) => {
    let colorInRGB = hexToRgb(hex);
    colorInRGB.r += value;
    colorInRGB.g += value;
    colorInRGB.b += value;
    if(colorInRGB.r > 255){colorInRGB.r = 255}
    if(colorInRGB.g > 255){colorInRGB.g = 255}
    if(colorInRGB.b > 255){colorInRGB.b = 255}
    return rgbToHex(colorInRGB);
  }

  const onTooltipToggle = () => setTooltipToggle(!tooltipToggle);

  const renderModal = () => {
    return (
      <Modal
        className={sdkThemeStyles.sdkModal}
        isOpen={openModal}
        centered={true}
        size="lg"
      >
        <ModalHeader className={sdkThemeStyles.modalHeader} toggle={toggleModal}>
          {modalContent.header}
        </ModalHeader>
        <ModalBody>
          {!!modalContent.content ? (
            <div className={sdkThemeStyles.modalBody}>
              {modalContent.content}
            </div>
          ) : null}
          <div className={sdkThemeStyles.modalActions}>
            <Button className={sdkThemeStyles.modalButtonSecondary} onClick={toggleModal}>
              No
            </Button>
            <Button
              className={sdkThemeStyles.modalButtonPrimary}
              onClick={() => {
                toggleModal();
                modalContent.actionCallback();
              }}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderNotification = () => {
    return (
      <>
        {!!openToast ? (
          <div className={commonStyles.toastNotification}>
            <div className={commonStyles.toastTickIcon}>
              <ToastTickIcon />
            </div>

            <div className={commonStyles.toastContents}>
              <span className={commonStyles.toastTitle}>Success!</span>
              <span className={commonStyles.toastBody}>
                Theme has been submitted for approval
              </span>
            </div>

            <div
              className={commonStyles.toastCloseIcon}
              onClick={() => {
                setOpenToast(false);
              }}
            >
              <CloseIcon />
            </div>

            <div className={commonStyles.toastDecoration}>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  const sdkCustomisationForm = (
    <div className={sdkThemeStyles.form}>
      <div className={sdkThemeStyles.formSection}>
        <div className={sdkThemeStyles.formSectionTitle}>Colour Settings</div>
        <div className={sdkThemeStyles.formInputBlock}>
          <div className={sdkThemeStyles.formInputLabel}>
            <Label>Select Header Bar / Button Primary Background Colour</Label>
          </div>
          <div className={sdkThemeStyles.formInputField}>
            <div className={sdkThemeStyles.radioColorCards}>
              <div
                id="sdkTheme-primaryColorCard"
                className={
                  approvalStatus === formStatus.pendingApproval
                    ? `${sdkThemeStyles.radioColorCard} ${sdkThemeStyles.disabled}`
                    : sdkThemeStyles.radioColorCard
                }
                style={{ backgroundColor: priColor.hex }}
                onClick={() => {
                  if(approvalStatus !== formStatus.pendingApproval){
                    openColorPicker("pri");
                  }
                }}
              >
                <div
                  className={sdkThemeStyles.colorCardHex}
                  style={{ color: isLowGamma(priColor.hex) ? "#fff" : "#333" }}
                >
                  {priColor.hex}
                </div>
              </div>
              {isPriColorPickerOpen ? (
                <div
                  className={sdkThemeStyles.radioColorPicker}
                  ref={priColorPickerRef}
                >
                  <ColorPicker
                    width={240}
                    height={150}
                    color={priColor}
                    onChange={event => handleColorPickerChange(event, "pri")}
                    hideHSV={true}
                    hideRGB={true}
                    dark={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={sdkThemeStyles.formInputBlock}>
          <div className={sdkThemeStyles.formInputLabel}>
            <Label>Header Bar Secondary Background Colour</Label>
          </div>
          <div className={sdkThemeStyles.formInputField}>
            <div className={sdkThemeStyles.radioColorCards}>
              <div
                id="sdkTheme-secondaryColorCard"
                className={
                  approvalStatus === formStatus.pendingApproval
                    ? `${sdkThemeStyles.radioColorCard} ${sdkThemeStyles.disabled}`
                    : sdkThemeStyles.radioColorCard
                }
                style={{ backgroundColor: secColor.hex }}
                onClick={() => {
                  if(approvalStatus !== formStatus.pendingApproval){
                    openColorPicker("sec");
                  }
                }}
              >
                <div
                  className={sdkThemeStyles.colorCardHex}
                  style={{ color: isLowGamma(secColor.hex) ? "#fff" : "#333" }}
                >
                  {secColor.hex}
                </div>
              </div>
              {isSecColorPickerOpen ? (
                <div
                  className={sdkThemeStyles.radioColorPicker}
                  ref={secColorPickerRef}
                >
                  <ColorPicker
                    width={240}
                    height={150}
                    color={secColor}
                    onChange={event => handleColorPickerChange(event, "sec")}
                    hideHSV={true}
                    hideRGB={true}
                    dark={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={sdkThemeStyles.formInputBlock}>
          <div className={sdkThemeStyles.formInputLabel}>
            <Label>Form Element Colour</Label>
            <div
              id="TooltipExample"
              className={sdkThemeStyles.formInputTooltipWrapper}
            >
              <TooltipIcon />
              <Tooltip
                className={sdkThemeStyles.formInputTooltip}
                placement="right"
                isOpen={tooltipToggle}
                target="TooltipExample"
                toggle={onTooltipToggle}
              >
                This will apply to all form UI components such as checkboxes, dropdown lists and radio buttons.
              </Tooltip>
            </div>
          </div>
          <div className={sdkThemeStyles.formInputField}>
            <div className={sdkThemeStyles.radioColorCards}>
              <div
                id="sdkTheme-formElemColorCard"
                className={
                  approvalStatus === formStatus.pendingApproval
                    ? `${sdkThemeStyles.radioColorCard} ${sdkThemeStyles.disabled}`
                    : sdkThemeStyles.radioColorCard
                }
                style={{ backgroundColor: formColor.hex }}
                onClick={() => {
                  if(approvalStatus !== formStatus.pendingApproval){
                    openColorPicker("form");
                  }
                }}
              >
                <div
                  className={sdkThemeStyles.colorCardHex}
                  style={{ color: isLowGamma(formColor.hex) ? "#fff" : "#333" }}
                >
                  {formColor.hex}
                </div>
              </div>
              {isFormColorPickerOpen ? (
                <div
                  className={sdkThemeStyles.radioColorPicker}
                  ref={formColorPickerRef}
                >
                  <ColorPicker
                    width={240}
                    height={150}
                    color={formColor}
                    onChange={event => handleColorPickerChange(event, "form")}
                    hideHSV={true}
                    hideRGB={true}
                    dark={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={sdkThemeStyles.formSection}>
        <div className={sdkThemeStyles.formSectionTitle}>Logo Settings</div>
        <div className={sdkThemeStyles.formInputBlock}>
          <div className={sdkThemeStyles.formInputLabel}>
            {!!!logoPreview.base64 ? (
              <Label>Upload Logo</Label>
            ) : (
              <Label>Logo Image</Label>
            )}
          </div>
          <div className={sdkThemeStyles.formInputField}>
            {!!!logoPreview.base64 ? (
              <>
                <BPPFileInput
                  onChange={logoPreviewOnChange}
                  fileTypes={["image/png", "image/jpeg", "image/svg+xml"]}
                  fileMaxSize={1048576}
                  invalid={!!logoPreviewErrors.logoImg}
                />
                {!!logoPreviewErrors.logoImg && (
                  <div className={sdkThemeStyles.sdkCustInputFieldError}>
                    {logoPreviewErrors.logoImg}
                  </div>
                )}
                <div className={sdkThemeStyles.sdkCustInputFieldRemark}>
                  Recommended size 256 px (width) x 96 px (height). Max file
                  size 1MB.
                </div>
              </>
            ) : (
              <>
                <div className={sdkThemeStyles.imgPreviewWrapper}>
                  <Button
                    className={sdkThemeStyles.imgPreviewClose}
                    onClick={logoPreviewOnCancel}
                    disabled={approvalStatus === formStatus.pendingApproval}
                  >
                    <CloseIcon />
                  </Button>
                  <div className={sdkThemeStyles.imgPreviewVisual}>
                    <img src={logoPreview.base64} />
                  </div>
                  <div className={sdkThemeStyles.imgPreviewInfo}>
                    <div className={sdkThemeStyles.previewInfoName}>
                      {logoPreview.name}
                    </div>
                    <div className={sdkThemeStyles.previewInfoSize}>
                      {(logoPreview.size / 1024).toFixed()}KB
                    </div>
                    <div className={sdkThemeStyles.previewInfoDimensions}>
                      {logoPreview.dimWidth}
                      <span> x </span>
                      {logoPreview.dimHeight}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {renderModal()}
      {renderNotification()}
    </div>
  );

  return (
    <div className={commonStyles.section}>
      <div className={commonStyles.filterBar}>
        <h1>Customise SDK</h1>
      </div>
      <div className={sdkThemeStyles.main}>
        <div className={commonStyles.backButton} onClick={backBtnToggle}>
          <BackIcon />
          <span>Back</span>
        </div>
        <div className={commonStyles.breadcrumbs}>
          <span>Dashboard / </span>
          <span className={commonStyles.highlighted}>
            Customise SDK
          </span>
        </div>
        {approvalStatus === formStatus.pendingApproval ? (
          <div className={`${commonStyles.alert} ${commonStyles.warn}`}>
            <div className={commonStyles.alertIcon}>
              <WarnIcon/>
            </div>
            <div className={commonStyles.alertMsg}>
              This customisation is pending approval. The changes will only be applied to the mobile SDK once approved.
            </div>
          </div>
        ) : null}
        {approvalStatus !== formStatus.new ? (
          <div className={sdkThemeStyles.formStatusBar}>
            {approvalStatus === formStatus.pendingApproval
             ? "Submitted"
             : "Last modified"
            }
            &nbsp;by&nbsp; 
            <b>{lastModifiedInfo.name}&nbsp;</b>
            on {moment(lastModifiedInfo.date).format("DD/MM/YYYY HH:mm:ss")}
          </div>
        ) : null}
        <Row>
          <Col xs="12" xl="6">
            {sdkCustomisationForm}
          </Col>
          <Col xs="12" xl="6">
            <SDKPreview
              primaryColor={priColor.hex}
              secondaryColor={secColor.hex}
              formElemColor={formColor.hex}
              logo={!!logoPreview.base64 ? logoPreview.base64 : DefaultLogo}
            />
          </Col>
          <Col xs="12" xl="6">
            <div className={sdkThemeStyles.sdkFormAction}>
              <Button
                type="button"
                className={sdkThemeStyles.sdkResetButton}
                disabled={approvalStatus === formStatus.pendingApproval}
                onClick={resetColors}
              >
                Restore to Default
              </Button>
              <Button
                type="button"
                className={sdkThemeStyles.sdkFormButton}
                disabled={approvalStatus === formStatus.pendingApproval}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withSidebarRefreshHandler(
  withLoading(withCustomAlert(SdkCustomisation))
);
