import React, { Component } from 'react';
import { Button, Form, ButtonGroup } from 'reactstrap';
import './FilterCheckbox.scss';

class FilterCheckbox extends Component {

    render() {
        return (
            <Form className="checkbox-source-filtering-container">
              {this.props.list.map(item => {
                return (
                  <ButtonGroup>
                    <Button className='error-filter-checkbox-btn'
                      onClick={() => this.props.onSelect(item.value)}
                      active={this.props.selectedError.includes(item.value)}
                    >
                      {
                        (this.props.selectedError.includes(item.value)) ? <i className="fa fa-check fa-lg mr-2" /> : ""
                      }
                      {item.label}
                    </Button>
                  </ButtonGroup>
                )
              })
              }
            </Form>
        );
    }
}

export default FilterCheckbox;



