export const sidebarRoutes = {
  dashboard: '/home/dashboard',
  vcc: "/home/vcc",
  fastcash: "/home/fastcash",
  usermgm: "/home/usermgm/",
  inApp: "/home/inApp",
  customiseSdk: "/home/customiseSdk"
}

export default {
  items: [
    {
      name: 'Dashboard',
      // TODO: switch to dashboard url once the dashboard page is ready
      // url: sidebarRoutes.dashboard,
      url: sidebarRoutes.vcc,
      icon: 'fa fa-line-chart'
    },
    {
      name: 'Search Customer',
      icon: 'fa fa-search',
      url: sidebarRoutes.vcc,
      children: [
        {
          name: 'Virtual Credit Card',
          url: sidebarRoutes.vcc,
        },
        // {
        //   name: 'Fast Cash',
        //   url: sidebarRoutes.fastcash,
        // }
        {
          name: 'User Management',
          url: sidebarRoutes.usermgm,
        },
        {
          name: 'In-App History',
          url: sidebarRoutes.inApp,
        }
      ]
    },
    {
      name: 'Customise SDK',
      url: sidebarRoutes.customiseSdk,
      icon: 'fa fa-paint-brush'
    },
  ]
};
