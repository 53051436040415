import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

class DBOSPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
    }

    onPrint = () => {
        console.log('onPrint');

        this.setState({
            isModalOpen: true
        })
    }

    onToggleModal = () => {
        this.setState(prevState => {
            return {
                isModalOpen: !prevState.isModalOpen
            }
        })
    }

    renderInvisibleButton = () => {
        return (
            <Button color="primary" style={{ display: 'none' }}>
                <i className="fa fa-print"></i> &nbsp; Print
            </Button>
        );
    }

    printContent = () => {
        console.log('printContent');
        this.printerRef.triggerRef.onClick();
    }

    render() {
        return (
            <div>
                <ReactToPrint
                    ref={ref => this.printerRef = ref}
                    trigger={() => this.renderInvisibleButton()}
                    content={() => this.toPrintRef}
                    onAfterPrint={this.onToggleModal}
                    pageStyle={this.props.pageStyle}
                />
                <Button color="primary" onClick={this.onPrint} style={this.props.overrideStyle?this.props.overrideStyle:styles.printBtn}>
                    <i className="fa fa-print"></i> &nbsp; Print
                </Button>
                <Modal isOpen={this.state.isModalOpen} onOpened={this.printContent} size='lg'>
                    <ModalHeader toggle={this.onToggleModal}>Print</ModalHeader>
                    <ModalBody>
                        <div ref={ref => this.toPrintRef = ref}>
                            {this.props.toPrint}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default DBOSPrint;

const styles = {
    printBtn: {
        position: 'fixed', 
        right: 0, 
        top: '90%',
        margin: 15,
        zIndex: 99
    },
}