import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { NavRoute } from 'core';

import {
  AppHeader,
  AppSidebar,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';
import './layout.scss';
import { getLoginUsername } from 'services/session';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '' }
  }

  async componentDidMount() {
    const username = await getLoginUsername();
    this.setState({ username: username });
  }

  onLogout = () =>{
    this.props.navigation.replace('/');
  }

  render() {

    return (
      <div className="app">
        <AppHeader className="topNav" fixed>
          <DefaultHeader username={this.state.username} onLogout={this.onLogout}/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar className="custom-nav" fixed display="lg">
            <AppSidebarNav className="sidebar" navConfig={navigation} {...this.props} />
          </AppSidebar>
          <main className="main main-container">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<NavRoute key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} />)
                    : (null);
                },
                )}
              </Switch>
          </main>
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
