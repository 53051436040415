import React, { Component } from 'react';
import './Popper.scss';
import { Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


class SCPopper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
    }

    handlePopper = (event) => {
        this.setState({ anchorEl: (this.state.anchorEl ? null : event.currentTarget) })
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    renderTriggleButton = () => {
        return (
            <div className={this.props.customStyle ? this.props.customStyle : 'popperToggle'} onClick={this.handlePopper} >
                {this.props.title}
                {this.props.noIcon ? ('') : (<i className='fa fa-sort-desc fa-xs caret' />)}
            </div>
        )
    }

    renderContent = () => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        
        return (
            <Popper
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                className='content'
                placement={this.props.placement ? this.props.placement : 'bottom'}
            >
                {this.props.content}
            </Popper>
        )
    }


    render() {
        if (this.props.closeOnContentClicked) {
            return (
                <>
                    <ClickAwayListener onClickAway={this.handleClose}>
                        {this.renderTriggleButton()}
                    </ClickAwayListener>
                    {this.renderContent()}
                </>
            )
        }
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <div>
                        {this.renderTriggleButton()}
                        {this.renderContent()}
                    </div>
                </ClickAwayListener>
            </>
        )
    }
}

export default SCPopper;