import React from "react";
import "./HelpGuide.scss";

const CancelIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.375308 2.1853C-0.091742 1.71825 -0.122879 0.981142 0.282055 0.478968L0.375115 0.375525C0.874439 -0.125079 1.68486 -0.125079 2.18527 0.375332L8.86493 7.05578L15.5454 0.375332C16.0125 -0.0917179 16.7496 -0.122854 17.2515 0.281765L17.3552 0.375139C17.8558 0.874463 17.8558 1.68489 17.3554 2.1853L10.674 8.86485L17.3552 15.5453C17.8224 16.0113 17.8536 16.7483 17.4488 17.2514L17.3554 17.3554C17.1123 17.5985 16.7873 17.7304 16.4504 17.7304C16.114 17.7304 15.7901 17.5988 15.5454 17.3554L8.86493 10.675L2.18489 17.3558C1.97576 17.5639 1.70722 17.6905 1.42332 17.7224L1.28029 17.7304C0.943368 17.7304 0.618367 17.5985 0.375308 17.3554C-0.125103 16.855 -0.125103 16.0446 0.375308 15.5455L7.0548 8.86485L0.375308 2.1853Z"
      fill="#333333"
    />
    <mask
      id="mask0"
      masktype="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375308 2.1853C-0.091742 1.71825 -0.122879 0.981142 0.282055 0.478968L0.375115 0.375525C0.874439 -0.125079 1.68486 -0.125079 2.18527 0.375332L8.86493 7.05578L15.5454 0.375332C16.0125 -0.0917179 16.7496 -0.122854 17.2515 0.281765L17.3552 0.375139C17.8558 0.874463 17.8558 1.68489 17.3554 2.1853L10.674 8.86485L17.3552 15.5453C17.8224 16.0113 17.8536 16.7483 17.4488 17.2514L17.3554 17.3554C17.1123 17.5985 16.7873 17.7304 16.4504 17.7304C16.114 17.7304 15.7901 17.5988 15.5454 17.3554L8.86493 10.675L2.18489 17.3558C1.97576 17.5639 1.70722 17.6905 1.42332 17.7224L1.28029 17.7304C0.943368 17.7304 0.618367 17.5985 0.375308 17.3554C-0.125103 16.855 -0.125103 16.0446 0.375308 15.5455L7.0548 8.86485L0.375308 2.1853Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);

const HelpGuide = props => (
  <div className="help-guide">
    <div className="help-modal">
      <div className="modal-header">
        <span className="modal-title">Help Guide</span>

        <div className="cancel-icon" onClick={props.closeGuide}>
          <CancelIcon />
        </div>
      </div>

      <div className="modal-body">
        <div className="left-nav">
          <ul>
            <li>Search Customer</li>
            <li>Apply Wealth product</li>
            <li>Apply Banca product</li>
            <li>Suitability Assessment</li>
            <li>Customer Find Fact Form</li>
          </ul>
        </div>

        <div className="content"></div>
      </div>
    </div>

    <div className="help-backdrop"></div>
  </div>
);

export default HelpGuide;
