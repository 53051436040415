import React, { Component } from 'react';
import { Form, FormGroup, Label, CustomInput } from 'reactstrap'
import './style.scss';

class Features extends Component {

    onFeatureToggled = (e) => {
       console.log('onFeatureToggled:',e)
    }

    isChecked = (facilityCode) =>{
        if(this.props.selectedFacilities)
        return this.props.selectedFacilities.indexOf(facilityCode) > 0;

        return false
    }

    renderCheckboxes() {
        return !this.props.facilityList ? (null) : this.props.facilityList.map((feature, index) => {
            return (
                <Form key={feature.code}>
                    <FormGroup check inline>
                        {/* <Label check className="features-container">
                            <CustomInput id={feature.code} type="checkbox" disabled={this.props.readonly} 
                            checked={this.isChecked(feature.code)} onClick={this.onFeatureToggled}
                            label={feature.name}/> */}
                            <div></div>
                            <div className="features-container">
                                <b>{index+1}. {feature.name}</b>
                            </div>
                        {/* </Label> */}
                    </FormGroup>
                </Form>
            )
        })
    }

    render() {
        return (
            this.renderCheckboxes()
        );
    }
}

export default Features;