import React, { Component } from 'react';
import { AppFooter } from '@coreui/react';
import footerStyles from './style.module.scss';
import Environment from '../../environment';

export default class Footer extends Component {

    render(){
        return(
            <AppFooter className={footerStyles.section}>
                <div className='container flush-max-width--'>
                    <div className={footerStyles.footerNote}>
                        {`Alliance Bank Virtual Credit Card Partner Portal v${Environment.appVersion}`}
                    </div>
                    <div className={footerStyles.footerNote}>
                        Copyright &copy; 2021 Alliance Bank Malaysia Berhad 198201008390 (88103-W)
                    </div>
                </div>
            </AppFooter>
        )
    }
}