import React, { Component } from 'react';
import PaginationComponent from "react-reactstrap-pagination";
import './style.scss';

class SCPagination extends Component {
    
    paginationRef;  

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            pagesCount: Math.ceil(this.props.dataSet.length / this.props.pageSize),
            displaySet: []
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount',this.state.currentPage);
        this.filterData()
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate',this.state.currentPage);
        if (prevProps.dataSet.length !== this.props.dataSet.length || prevProps.pageSize !== this.props.pageSize) {
            let pagesCount = Math.ceil(this.props.dataSet.length / this.props.pageSize)
            
            if(this.paginationRef)
            {
                //to force PaginationComponent to page 1
                this.paginationRef.setState({firstPaginationNumber:1})
            }
            this.setState({ pagesCount, currentPage: 1 }, this.filterData)
        }
    }

    handleClick(currentPage) {
        console.log('handleClick',currentPage);
        this.setState({
            currentPage: currentPage
        }, this.filterData)
    }

    filterData = () => {
        console.log('filterData',this.state.currentPage);
        const { currentPage } = this.state;
        console.log('before:', this.props.dataSet)
        const filteredDataSet = this.props.dataSet.slice((currentPage - 1) * this.props.pageSize, currentPage * this.props.pageSize)
        console.log('after:', filteredDataSet)
        this.setState({ displaySet: filteredDataSet })
    }

    render() {
        return (            
            <React.Fragment>
                <PaginationComponent
                    totalItems={this.props.dataSet.length}
                    pageSize={this.props.pageSize}
                    onSelect={this.handleClick}
                    maxPaginationNumbers={5}
                    activePage={this.state.currentPage}
                    ref={ref => this.paginationRef=ref}
                />
                {
                    this.props.renderItem(this)
                }
            </React.Fragment>
        )
    }
}

export default SCPagination;

SCPagination.defaultProps = {
    dataSet: [],
    pageSize: 20,
    renderItem: () => { }
}