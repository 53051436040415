import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './style.scss';

class UnderlineFilterButton extends Component {

    render() {
        return (
            <div>
                {
                    this.props.list.map((item, key) =>
                        <Button key={key} className="underline-button"
                            onClick={() => this.props.onTapped(item)}
                            active={(this.props.selectedValue.value === item.value) ? true : false}>
                            {item.label}
                        </Button>
                    )
                }
            </div>
        );
    }
}

export default UnderlineFilterButton;



