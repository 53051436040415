import { CustomError, HTTP } from "core";
import moment from "moment";
import Environment from "../../../environment";
import { SessionService } from "services";

const invalidSessionError = bool => {
  if (bool) {
    const error = new CustomError().refreshTokenExpired();
    return Promise.reject(error);
  }
};

const host = Environment.host;
const dummyHost = Environment.dummyHost;
let isDummy = false;

export const getVccApplications = (
  {
    from_date,
    to_date,
    page,
    size,
    partner_profile_id,
    customer_name,
    is_enroll
  } = {},
  isProtectedWebService = true
) => {
  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/forms?from_date=${from_date}&to_date=${to_date}&page=${page}&size=${size}`;
    if (partner_profile_id) pportalUrl = pportalUrl + `&partner_profile_id=${partner_profile_id}`;
    if (customer_name) pportalUrl = pportalUrl + `&customer_name=${customer_name}`;
    if (is_enroll) pportalUrl = pportalUrl + `&is_enroll=${is_enroll}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/0b153e96-a55b-478f-9efa-ab7defd44794";
    //delete dummy url 
    //https://designer.mocky.io/manage/delete/0b153e96-a55b-478f-9efa-ab7defd44794/EXoNl4SZetNORuKuqj2sf4cMzrh6GrgnSjsy
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getVccApplicationsCsv = (
  {
    from_date,
    to_date,
    page,
    size,
    partner_profile_id,
    customer_name,
    is_enroll
  } = {},
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/forms-download?from_date=${from_date}&to_date=${to_date}&page=${page}&size=${size}`;
    if (partner_profile_id) pportalUrl = pportalUrl + `&partner_profile_id=${partner_profile_id}`;
    if (customer_name) pportalUrl = pportalUrl + `&customer_name=${customer_name}`;
    if (is_enroll) pportalUrl = pportalUrl + `&is_enroll=${is_enroll}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/0b153e96-a55b-478f-9efa-ab7defd44794";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/0b153e96-a55b-478f-9efa-ab7defd44794/EXoNl4SZetNORuKuqj2sf4cMzrh6GrgnSjsy
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getFastCashApplications = (
  {
    from_date,
    to_date,
    page,
    size,
    refno,
    full_name,
    nric,
    mobile_no,
    email,
    status
  } = {},
  isProtectedWebService = true
) => {
  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/fastcash/customers?from_date=${from_date}&to_date=${to_date}&page=${page}&size=${size}`;
    if (refno) pportalUrl = pportalUrl + `&refno=${refno}`;
    if (full_name) pportalUrl = pportalUrl + `&full_name=${full_name}`;
    if (nric) pportalUrl = pportalUrl + `&nric=${nric}`;
    if (mobile_no) pportalUrl = pportalUrl + `&mobile_no=${mobile_no}`;
    if (email) pportalUrl = pportalUrl + `&email=${email}`;
    if (status) pportalUrl = pportalUrl + `&status=${status}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/0b153e96-a55b-478f-9efa-ab7defd44794";
    //delete dummy url 
    //https://designer.mocky.io/manage/delete/0b153e96-a55b-478f-9efa-ab7defd44794/EXoNl4SZetNORuKuqj2sf4cMzrh6GrgnSjsy
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getFastCashApplicationsCsv = (
  {
    from_date,
    to_date,
    page,
    size,
    refno,
    full_name,
    nric,
    mobile_no,
    email,
    status
  } = {},
  isProtectedWebService = true
) => {


  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/fastcash/customers-download?from_date=${from_date}&to_date=${to_date}&page=${page}&size=${size}`;
    if (refno) pportalUrl = pportalUrl + `&refno=${refno}`;
    if (full_name) pportalUrl = pportalUrl + `&full_name=${full_name}`;
    if (nric) pportalUrl = pportalUrl + `&nric=${nric}`;
    if (mobile_no) pportalUrl = pportalUrl + `&mobile_no=${mobile_no}`;
    if (email) pportalUrl = pportalUrl + `&email=${email}`;
    if (status) pportalUrl = pportalUrl + `&status=${status}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/0b153e96-a55b-478f-9efa-ab7defd44794";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/0b153e96-a55b-478f-9efa-ab7defd44794/EXoNl4SZetNORuKuqj2sf4cMzrh6GrgnSjsy
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getUsers = (
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    console.log(Environment.host);
    let pportalUrl = host + `/vcc/pportal/protected/v1/users`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/d73a1684-7fac-4eaa-b0c0-ca9995e07c47";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/d73a1684-7fac-4eaa-b0c0-ca9995e07c47/7YE4zs6PMe3kLVxLPxy1j6K975vaSHLHzSji
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getUserDetails = (userId,
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/users/` + userId;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/d73a1684-7fac-4eaa-b0c0-ca9995e07c47";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/d73a1684-7fac-4eaa-b0c0-ca9995e07c47/7YE4zs6PMe3kLVxLPxy1j6K975vaSHLHzSji
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const createUser = (
  {
    email,
    firstName,
    lastName,
    mobileNo,
    portalGroup
  } = {},
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/users`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/91398ba6-c82e-4609-af8e-410378ce618f";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/641a6ebf-19ce-4602-b6f8-74293a91173d/kiR7umggrXQkUdVtdNwcbgiZhIKQG6tBTHnp
    url = dummyUrl;
  }

  let payload = {};
  payload.email = email;
  payload.firstName = firstName;
  payload.lastName = lastName;
  payload.portalGroup = {};
  payload.portalGroup.path = portalGroup;
  payload.attributes = {};
  payload.attributes.phoneNumber = [];
  payload.attributes.phoneNumber.push(mobileNo);

  console.log(JSON.stringify(payload));

  return HTTP.postData( url, payload, isProtectedWebService);
};

export const updateUser = (
  {
    userId,
    firstName,
    lastName,
    mobileNo,
    role,
    enabled
  } = {},
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/users/` + userId;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/91398ba6-c82e-4609-af8e-410378ce618f";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/641a6ebf-19ce-4602-b6f8-74293a91173d/kiR7umggrXQkUdVtdNwcbgiZhIKQG6tBTHnp
    url = dummyUrl;
  }

  let payload = {};
  payload.firstName = firstName;
  payload.lastName = lastName;
  payload.enabled = enabled;
  payload.portalGroup = {};
  payload.portalGroup.path = role;
  payload.attributes = {};
  payload.attributes.phoneNumber = [];
  payload.attributes.phoneNumber.push(mobileNo);

  console.log(JSON.stringify(payload));

  return HTTP.putData( url, payload, isProtectedWebService);
};

export const deleteUser = (
  userId,
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/users/` + userId;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/2a9e9e05-3191-443f-8191-f0caac7ec967";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/2a9e9e05-3191-443f-8191-f0caac7ec967/TZMA2pLJYrQzKQnCkSLKpPTroRkKAv92Fi9N
    url = dummyUrl;
  }

  let payload = {};

  return HTTP.deleteData( url, payload, isProtectedWebService);
};

export const resetPassword = (
  username,
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/users/` + username + "/reset-password";

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/91398ba6-c82e-4609-af8e-410378ce618f";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/641a6ebf-19ce-4602-b6f8-74293a91173d/kiR7umggrXQkUdVtdNwcbgiZhIKQG6tBTHnp
    url = dummyUrl;
  }

  let payload = {};

  return HTTP.putData( url, payload, isProtectedWebService);
};

export const forgotPassword = (
  username,
  isProtectedWebService = false
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + "/vcc/pportal/public/v1/forgot-password";

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/a22c5536-182d-4814-a4f8-a4f18edcc719";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/a22c5536-182d-4814-a4f8-a4f18edcc719/qWVVfaNfoI1POOkELHVDODBJqiphUg2Q9wtx
    url = dummyUrl;
  }

  let payload = {};
  payload.username = username;

  return HTTP.postData( url, payload, isProtectedWebService);
};

export const updateTempPassword = (
  username,
  oldEncPwd,
  newEncPwd,
  isProtectedWebService = false
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + "/vcc/pportal/public/v1/update-temp-password";

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/91398ba6-c82e-4609-af8e-410378ce618f";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/641a6ebf-19ce-4602-b6f8-74293a91173d/kiR7umggrXQkUdVtdNwcbgiZhIKQG6tBTHnp
    url = dummyUrl;
  }

  let payload = {};
  payload.username = username;
  payload.oldEncPwd = oldEncPwd;
  payload.newEncPwd = newEncPwd;

  return HTTP.postData( url, payload, isProtectedWebService);
};

export const getInAppPurchaseHistory = (
  {
    from_date,
    to_date,
    page,
    size,
    payment_status
  } = {},
  isProtectedWebService = true
) => {
  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/in-app?from_date=${from_date}&to_date=${to_date}&page=${page}&size=${size}`;
    if (payment_status) pportalUrl = pportalUrl + `&payment_status=${payment_status}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/3b025426-268e-48cb-a1fb-2558094067cf";
    //delete dummy url 
    //https://designer.mocky.io/manage/delete/3b025426-268e-48cb-a1fb-2558094067cf/8HonjOwAo4pPwQFUb7quKBIzGhxqbOJHTAQo
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getInAppPurchaseHistoryCsv = (
  {
    from_date,
    to_date,
    payment_status
  } = {},
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    from_date = moment(from_date).format("yyyy-MM-DD");
    to_date = moment(to_date).format("yyyy-MM-DD");
    let pportalUrl =
      host +
      `/vcc/pportal/protected/v1/in-app-download?from_date=${from_date}&to_date=${to_date}`;
    if (payment_status) pportalUrl = pportalUrl + `&payment_status=${payment_status}`;

    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/ca1d56f0-eec4-4ca5-9180-a362e2df12bc";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/ca1d56f0-eec4-4ca5-9180-a362e2df12bc/hUpVbG1VwoPNe8TD1CoLjtKnoMX1EmCDIG36
    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const getThemeColors = (
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/customize-sdk`;
    url = pportalUrl;
  } else {
    // new application
    // let dummyUrl = dummyHost + "/v3/854fa884-a278-46bd-8048-d53465a83c13";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/854fa884-a278-46bd-8048-d53465a83c13/g0AQ634bvzdOXvH7A7hQ6OLQ897Gf3V7pIEL

    // first time submission + pending approval 
    // let dummyUrl = dummyHost + "/v3/df151041-f3a8-4916-87a2-f9341099a7d1";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/df151041-f3a8-4916-87a2-f9341099a7d1/w2aTZ44TjTOd2yySxkSjPtAbM5Y8EI06IvGz

    // approved
    // let dummyUrl = dummyHost + "/v3/f7ae3a2c-4bf7-43df-b95a-8ceaec9e31b9";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/f7ae3a2c-4bf7-43df-b95a-8ceaec9e31b9/7relg5LvCTBc2trheKO7Nf3iZ2j160UYZeo0

    // 2nd time submission + pending approval
    let dummyUrl = dummyHost + "/v3/29033e19-4d11-4b59-8276-a1fa8ccafa9d";
    //delete dummy url
    //https://designer.mocky.io/manage/delete/29033e19-4d11-4b59-8276-a1fa8ccafa9d/ti2q541edSyE7tzQ1lAZmWqr69HhRTBBck91

    url = dummyUrl;
  }

  return HTTP.getData(url, isProtectedWebService);
};

export const postThemeColors = (
  payload,
  isProtectedWebService = true
) => {

  let url = "";
  if (!isDummy) {
    invalidSessionError(
      isProtectedWebService && !SessionService.isValidSession()
    );

    let pportalUrl = host + `/vcc/pportal/protected/v1/customize-sdk`;
    url = pportalUrl;
  } else {
    let dummyUrl = dummyHost + "/v3/cb8cc693-6e2c-4c8b-95fa-ccb0122991e4";
    console.log(dummyUrl);
    //delete dummy url
    //https://designer.mocky.io/manage/delete/cb8cc693-6e2c-4c8b-95fa-ccb0122991e4/X4tOARzPRm4bWdaINWZkgeHQ592qo2hNpl3w
    url = dummyUrl;
  }

  return HTTP.postData(url, payload, isProtectedWebService);
};