import React, { Component } from "react";
import "./Toast.scss";
import {ReactComponent as ToastTickIcon} from "./ToastTickIcon.svg"
import {ReactComponent as ToastCloseIcon} from "./ToastCloseIcon.svg"

class SCToast extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="toast-notification">
        <div className="toast-tick-icon"><ToastTickIcon /></div>

        <div className="toast-contents">
          {!!this.props.title ? (
            <span className="toast-title">
              {this.props.title}
            </span>
          ): null }
          {!!this.props.body ? (
            <span className="toast-body">
              {this.props.body}
            </span>
          ): null }
        </div>

        <div className="toast-close-icon" onClick={() => {
          if(!!this.props.onClose){
            this.props.onClose();
          }
        }}>
          <ToastCloseIcon />
        </div>

        <div className="toast-decoration">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default SCToast;
