import React, { Component } from 'react';
import './style.scss';

const withLoading = (WrappedComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isShowLoading: false,
                loadingText: "Please Wait"
            }
        }

        showLoading = (loadingText) => {
            this.setState((prevState) => {
                return {
                    isShowLoading: true,
                    loadingText: loadingText ? loadingText : prevState.loadingText
                }
            })
        }

        hideLoading = () => {
            this.setState({
                isShowLoading: false
            })
        }

        renderLoading = () => {
            return (
                <div className="overlay">
                    <div className="loading"></div>
                    <div className="loading-text-container">
                        <p className="loading-text">{this.state.loadingText}</p>
                    </div>
                </div>
            )
        }

        render() {
            return (
                <React.Fragment>
                    {
                        this.state.isShowLoading ? this.renderLoading() : null
                    }
                    <WrappedComponent showLoading={this.showLoading} hideLoading={this.hideLoading} {...this.props} />
                </React.Fragment>
            )
        }

    }
}

export default withLoading;
