import React, { Component } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import './style.scss';

class ReadOnlyTextbox extends Component {

    determineFormStyle = () => {
        let className = '';
        if(this.props.transparent)
        {
            className = 'readOnlyTextBox-transparent';
        }else{
            className='readOnlyTextBox-form'
        }
        return className;
    }

    render() {
        return (
            <Form>
                <FormGroup className={this.determineFormStyle()}>
                    <Label className='readOnlyTextBox-label'>{ this.props.label }</Label> <br />
                    {this.props.label2 ? <Label className='readOnlyTextBox-label'>{ this.props.label2 }</Label> : ''}
                    <Input className='readOnlyTextBox-input' plaintext>{this.props.field}</Input>
                </FormGroup>
            </Form>
        );
    }
}

export default ReadOnlyTextbox;
